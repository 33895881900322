import { changeDefaultWorkspaceApi, updateWorkspaceApi, createWorkspaceApi } from '../api/ServerApi';
import { getConfig } from '../config/config';
import { initApi } from '../api/BaseApi';
const config = getConfig();

const ZONE = 'STORE/ACCOUNT/'

const INIT_ACCOUNT_INFO = ZONE + 'INIT_ACCOUNT_INFO'
const INIT_WORKSPACE_APPS = ZONE + 'INIT_WORKSPACE_APPS'
const SET_DEFAULT_WORKSPACE = ZONE + 'SET_DEFAULT_WORKSPACE'
const CREATE_WORKSPACE = ZONE + 'CREATE_WORKSPACE'
const UPDATE_WORKSPACE = ZONE + 'UPDATE_WORKSPACE'
const OPEN_CREATE_WORKSPACE_MODAL = ZONE + 'OPEN_CREATE_WORKSPACE_MODAL'
const CLOSE_CREATE_WORKSPACE_MODAL = ZONE + 'CLOSE_CREATE_WORKSPACE_MODAL'
const FORCE_REFRESH = ZONE + 'FORCE_REFRESH'
const SET_CURRENT_APP_ID = ZONE + 'SET_CURRENT_APP_ID'
const OPEN_FLOW_ASSISTANT_MODAL = ZONE + 'OPEN_FLOW_ASSISTANT_MODAL'
const CLOSE_FLOW_ASSISTANT_MODAL = ZONE + 'CLOSE_FLOW_ASSISTANT_MODAL'
const SET_CAN_USE_FLOW_ASSISTANT = ZONE + 'SET_CAN_USE_FLOW_ASSISTANT'
const TRIGGER_MENU_STATUS = ZONE + 'TRIGGER_MENU_STATUS'

const defaultState = {
  account_info: null,
  token: null,
  workspaces: null,
  current_workspace: null,
  show_create_workspace_modal: false,
  apps: {},
  refresh: null,
  current_app_id: null,
  show_flow_assistant_modal: false,
  can_use_flow_assistant: false,
  menu_collapsed: false,
}

export const triggerMenuStatus = (collapsed: boolean) => {
  return ({type: TRIGGER_MENU_STATUS, data: collapsed})
}


export const setCanUseFlowAssistant = (data: boolean) => {
  return ({type: SET_CAN_USE_FLOW_ASSISTANT, data: data})
}

export const openFlowAssistantModal = () => {
  return ({type: OPEN_FLOW_ASSISTANT_MODAL})
}

export const closeFlowAssistantModal = () => {
  return ({type: CLOSE_FLOW_ASSISTANT_MODAL})
}

export const forceRefresh = () => {
  return ({type: FORCE_REFRESH, data: Math.random()})
}

export const setCurrentAppId = (id) => {
  return ({type: SET_CURRENT_APP_ID, data: id})
}

export const initAccountInfo = (data) => {
  return ({type: INIT_ACCOUNT_INFO, data: data})
}

export const setDefaultWorkspace = ({id, success, fail}) => (dispatch) => {
  changeDefaultWorkspaceApi({id: id}).then((res) => {
    if(res.status) {
      dispatch({type: SET_DEFAULT_WORKSPACE, data: id})
      success && success(res)
    } else {
      fail && fail(res)
    }
  })
}

export const initWorkspaceApp = (data) => {
  return ({type: INIT_WORKSPACE_APPS, data: data})
}

export const createWorkspace = ({name, success, fail }) => (dispatch) => {
  createWorkspaceApi({name: name}).then((res) => {
    if(res.status) {
      dispatch({type: CREATE_WORKSPACE, data: {
        ...res.data,
        member_type: "OWNER"
      }})
      success && success(res)
    } else {
      fail && fail(res)
    }
  })
}

export const updateWorkspace = ({id, name, success, fail }) => (dispatch) => {
  updateWorkspaceApi({workspace_id: id, name: name}).then((res) => {
    if(res.status) {
      dispatch({type: UPDATE_WORKSPACE, data: {
        ...res.data,
        member_type: "OWNER"
      }})
      success && success()
    } else {
      fail && fail()
    }
  })
}

export const openCreateWorkspaceModal = () => {
  return ({type: OPEN_CREATE_WORKSPACE_MODAL})
}

export const closeCreateWorkspaceModal = () => {
  return ({type: CLOSE_CREATE_WORKSPACE_MODAL})
}

const cal_workspaces_order_by_default = (workspaces, default_id) => {
  return workspaces.map((workspace, index) => ({
    ...workspace,
    default: (!!default_id && workspace.id === default_id) || (!default_id && index === 0) ? 1 : 0
  })).sort((a, b)=> b.default-a.default)
}

export const reducer = (state = defaultState, action) => {
  let workspaces;
  switch(action.type) {
    case INIT_ACCOUNT_INFO:
      workspaces = cal_workspaces_order_by_default(
        action.data.workspaces,
        action.data.default_workspace_id
      )
      return {
        ...state,
        account_info: action.data.account_info,
        token: action.data.token,
        workspaces: workspaces,
        current_workspace: workspaces[0]
      }
    case SET_DEFAULT_WORKSPACE:
      workspaces = cal_workspaces_order_by_default(
        state.workspaces,
        action.data
      )
      return {
        ...state,
        workspaces: workspaces,
        current_workspace: workspaces[0]
      }
    case CREATE_WORKSPACE:
      return {
        ...state,
        workspaces: [...state.workspaces, action.data],
        current_workspace: state.current_workspace || action.data
      }
    case UPDATE_WORKSPACE:
      return {
        ...state,
        workspaces: state.workspaces.map(workspace => ({
          ...workspace,
          ...(workspace.id === action.data.id ? action.data : null)
        })),
        ...(action.data.id === state.current_workspace?.id ? {
          current_workspace: action.data
        } : {})
      }
    case INIT_WORKSPACE_APPS:
      return {
        ...state,
        apps: action.data.reduce((result, item: App, index: number) => {
          if(!item.link_active) {
            delete result[item.id];
            return result;
          }
          return {
            ...result,
            [item.id]: {
              ...item,
              display_name: item?.display_name || item?.app_manifest?.display_name,
              doc_url: {
                zh: `${config.api_baseurl}/${
                  item?.app_manifest?.help_doc_endpoint?.zh ||
                  item?.app_manifest?.help_doc_endpoint?.en ||
                  item?.app_manifest?.help_doc_endpoint
                }/`,
                en: `${config.api_baseurl}/${
                  item?.app_manifest?.help_doc_endpoint?.en ||
                  item?.app_manifest?.help_doc_endpoint?.zh ||
                  item?.app_manifest?.help_doc_endpoint
                }/`,
              },
              api: initApi(`${config.api_baseurl}/${item?.app_manifest?.endpoint}/api`, state.token),
              server_url: `${config.api_baseurl}/${item?.app_manifest?.endpoint}`,
              color: ["#52c41a", "#faad14", "#E52323", "#2A61FF"][index % 4]
            }
          }
        }, (state.apps || {}))
      }
    case OPEN_CREATE_WORKSPACE_MODAL:
      return {
        ...state,
        show_create_workspace_modal: true
      }
    case CLOSE_CREATE_WORKSPACE_MODAL:
      return {
        ...state,
        show_create_workspace_modal: false
      }
    case FORCE_REFRESH:
      return {
        ...state,
        refresh: action.data
      }
    case SET_CURRENT_APP_ID:
      return {
        ...state,
        current_app_id: action.data
      }
    case OPEN_FLOW_ASSISTANT_MODAL:
      return {
        ...state,
        show_flow_assistant_modal: true
      }
    case CLOSE_FLOW_ASSISTANT_MODAL:
      return {
        ...state,
        show_flow_assistant_modal: false
      }
    case SET_CAN_USE_FLOW_ASSISTANT:
      return {
        ...state,
        can_use_flow_assistant: action.data
      }
    case TRIGGER_MENU_STATUS:
      return {
        ...state,
        menu_collapsed: action.data
      }
    default:
      return state
  }
}