import React from 'react';
import ReactDOM from 'react-dom';
import "antd/dist/reset.css";
import "./common/style/reset.css";
import "./common/style/global.css";
import App from './App';
import reportWebVitals from './reportWebVitals';
import './config/i18n';
import i18next from './config/i18n';
import { Provider } from 'react-redux';
import store from './store';
import AuthService from './utils/AuthService';
import GlobalLoader, {
  LoadingView,
  GlobalLoadingView,
} from "./components/GlobalLoading";
import CommonErrorView, { ErrorBoundary } from "./components/CommonErrorView";
import { getConfig } from "./config/config";
const config = getConfig();

ReactDOM.render(
  <LoadingView />,
  document.getElementById('root')
);


let isIframeInSafari = false;
try {
  isIframeInSafari = window !== window?.parent &&
    /Safari/.test(navigator.userAgent) &&
    !/Chrome/.test(navigator.userAgent);
} catch(e) {
  console.log(e);
}
if(isIframeInSafari) {
  ReactDOM.render(
    <CommonErrorView message='不支持Safari浏览器，可换成其他浏览器继续使用' />,
    document.getElementById('root')
  );
} else if (process.env.REACT_APP_MODE === 'simple-dev') {
  ReactDOM.render(
    <ErrorBoundary>
      <Provider store={store}>
        <GlobalLoadingView
          ref={(globalLoaderRef) => {
            GlobalLoader.setGlobalLoader(globalLoaderRef);
          }}
        />
        <App />
      </Provider>
    </ErrorBoundary>,
    document.getElementById('root')
  );
} else {
  AuthService.initKeycloak(
    () => {
      ReactDOM.render(
        <ErrorBoundary>
          <Provider store={store}>
            <GlobalLoadingView
              ref={(globalLoaderRef) => {
                GlobalLoader.setGlobalLoader(globalLoaderRef);
              }}
            />
            <App />
          </Provider>
        </ErrorBoundary>,
        document.getElementById('root')
      );
    },
    () => {
      AuthService.login(
        !!config.auth_idp_hint ? {
          idpHint: config.auth_idp_hint
        } : {
          locale: i18next?.language === 'en' ? "en" : "zh-CN"
        }
      );
    }
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
