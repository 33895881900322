import React, { useEffect, useState, useImperativeHandle, forwardRef } from "react";
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import type { RadioChangeEvent, CheckboxChangeEvent, CheckboxValueType } from 'antd';
import {
  Checkbox,
  Form,
  Modal,
  Button,
  Select,
  Typography,
  Row,
  Col,
  Radio,
} from "antd";
import { SwapOutlined } from '@ant-design/icons';
import { useHistory, useParams } from "react-router-dom";
import { getComparisonDataList } from '../../api/AppApi'

const RunInstanceCompareModal = (props) => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [show, setShow] = useState();
  const [sheetType, setSheetType] = useState<any>();
  const [form] = Form.useForm();
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [sheetList, setSheetList] = useState({ input: [], output: []});
  const params = useParams();

  useEffect(() => {
    getComparisonDataList(props.apps[params?.app_id]?.api)?.then((res) => {
      if(res?.status) {
        setSheetList({
          input: res?.data?.input || [],
          output: res?.data?.output || []
        })
      }
    })
  }, [props.apps, params?.app_id])

  useImperativeHandle(props.refInstance, () => ({
    show: (data: List<RunInstance>) => {
      setIndeterminate(false);
      setCheckAll(false);
      setSheetType("input");
      setShow(data);
      form.resetFields();
      form.setFieldsValue({
        origin_instance: data[0]?.id,
        instance: data[1]?.id,
        tables: []
      })
    }
  }))

  const closeModal = () => {
    setShow(undefined);
  };

  const handleExchangeInstance = () => {
    const data = form.getFieldsValue();
    form.setFieldsValue({
      origin_instance: data?.instance,
      instance: data?.origin_instance,
    });
  }

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    form.setFieldValue(
      "tables",
      e.target.checked
        ? (sheetList[sheetType] || []).map((dfSheet) => dfSheet.df_name)
        : []
    );
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const onTableChange = (list: CheckboxValueType[]) => {
    setIndeterminate(
      !!list.length && list.length < ((sheetList[sheetType] || [])).length
    );
    setCheckAll(list.length >= ((sheetList[sheetType] || [])).length);
  };

  const handleSheetTypeChange = (e: RadioChangeEvent) => {
    setSheetType(e.target.value);
    setIndeterminate(false);
    setCheckAll(false);
    form.setFieldsValue({
      tables: []
    })
  }

  const handleSubmit = async () => {
    try {
      await form.validateFields();
    } catch (e) {
      return;
    }
    const data = form.getFieldsValue();
    history.push({
      pathname: `/app/${
        params.app_id
      }/compare/${
        `${data.origin_instance}%20${data.instance}`
      }/${
        (data.tables || []).join("%20")
      }`,
    })
  };

  return (
    <>
      <Modal
        centered
        width={550}
        forceRender={true}
        title={t('instance.action.compare_title')}
        open={!!show}
        onCancel={closeModal}
        onOk={handleSubmit}
        cancelText={t('common.cancel')}
        okText={t('common.comfirm')}
      >
        <Form name='instance compare form' form={form}>
          <Typography.Paragraph style={{ fontWeight: 'bold' }}>
            {t("instance.action.file_name")}
          </Typography.Paragraph>
          <div
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: 'space-between'
            }}
          >
            <Form.Item
              name="origin_instance"
              style={{ width: 'calc(50% - 2.5em)' }}
            >
              <Select
                disabled
                style={{ width: "100%" }}
                className='no-disabled-style'
                options={(show || []).map(item => ({
                  label: item.name,
                  value: item.id
                }))}
              />
            </Form.Item>
            <Button
              type='text'
              style={{ margin: "0 1em 24px 1em" }}
              icon={<SwapOutlined /> }
              onClick={handleExchangeInstance}
            />
            <Form.Item
              name="instance"
              style={{ width: 'calc(50% - 2.5em)' }}
            >
              <Select
                disabled
                className='no-disabled-style'
                style={{ width: "100%" }}
                options={(show || []).map(item => ({
                  label: item.name,
                  value: item.id
                }))}
              />
            </Form.Item>
          </div>
          <div className='mb-4'>
            <span style={{ fontWeight: 'bold' }}>
              {t("instance.action.view_type")}
            </span>
            <Radio.Group
              className='mx-4'
              value={sheetType}
              onChange={handleSheetTypeChange}>
              <Radio value="input">{t('instance.input_view')}</Radio>
              <Radio
                disabled={
                  (show || []).filter(item =>
                    item?.solving_status?.status === 'Succeeded'
                  ).length < 2
                }
                value="output">
                {t('instance.output_view')}
              </Radio>
            </Radio.Group>
          </div>
          {
            !!sheetType && <Typography.Paragraph>
              <span  style={{ fontWeight: 'bold' }}>
                {t('instance.action.compare_table_name')}
              </span>
              <Checkbox
                style={{ marginLeft: "2em" }}
                indeterminate={indeterminate}
                onChange={onCheckAllChange}
                checked={checkAll}
              >
                {t('common.check_all')}
              </Checkbox>
            </Typography.Paragraph>
          }
          <Form.Item
            style={{ width: "100%" }}
            name="tables"
            rules={[{ required: true, message: t('common.is_blank', {name: t('instance.action.compare_title')}) }]}
          >
            <Checkbox.Group
              style={{ width: "100%" }}
              onChange={onTableChange}
              className="compare-checkbox"
            >
              <Row gutter={[16, 10]} style={{ width: "100%" }}>
                {(sheetList[sheetType] || []).map((dfSheet) => (
                  <Col key={dfSheet.df_name} xs={12} sm={8}>
                    <Checkbox value={dfSheet.df_name}>
                      <Typography.Text ellipsis={{
                        tooltip: {
                          placement: "bottomLeft",
                          overlayClassName: 'description_tooltip',
                        }
                      }}>
                        {(dfSheet.name || {})[i18n.resolvedLanguage === 'zh' ? 'zh' : 'en'] || dfSheet.df_name}
                      </Typography.Text>
                    </Checkbox>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

const mapStateToProps = (store) => ({
  apps: store.account.apps,
})

let Component = connect(mapStateToProps, {})(RunInstanceCompareModal)
export default forwardRef((props, ref) => <Component {...props} refInstance={ref} />);