import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { openFlowAssistantModal } from '../../../store/account';
import { Layout, Button, Avatar, Typography, Dropdown, Menu, Drawer, Space, Image, Tooltip } from 'antd';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  CloseOutlined,
  WifiOutlined,
  DownOutlined,
} from '@ant-design/icons';
import NavMenu from './NavMenu';
import Language from '../../Language';
import HeaderSearch from './HeaderSearch';
import WorkspacesManagement from '../../WorkspacesManagement';
import { useTranslation } from 'react-i18next';
import { useLocation, matchPath } from 'react-router-dom';
import MenuAppsIcon from '../../../common/images/icons/menu-apps-icon.png';
import LogoIcon from '../../../common/images/icons/logo.png';
import UseDocumentTitle from "../../../procurement/components/UseDocumentTitle";
import AuthService from '../../../utils/AuthService';
import viewUtils from '../../../utils/viewUtils';
import GuideIcon from '../../../common/images/icons/guide-icon.png';
import FlowAssistantIcon from '../../../common/images/icons/flow-assistant-icon.png';

import { getConfig } from '../../../config/config';
import { getHealthApi } from '../../../api/ServerApi';
import Novu from './Novu';
const config = getConfig();

const Header = (props: any) => {
    const { t, i18n } = useTranslation();
    const [ menuState, setMenuState ] = useState(false);
    const handleMenuClose = () => setMenuState(false);
    const handleMenuOpen = () => setMenuState(true);
    const [online, setOnline] = useState(navigator.onLine);

    const location = useLocation();
    const currentAppId = matchPath(location.pathname, { path: '/app/:app_id', strict: true })
    const currentApp = currentAppId && props.apps[currentAppId.params.app_id]

    UseDocumentTitle(currentApp ? `${i18n.resolvedLanguage === 'en' ? currentApp.display_name.en : currentApp.display_name.zh}` : t(
        config.isSupplyChainAIPlatform ? 'supply_chain_ai_platform.app_title' : 'app_title'
    ))

    useEffect(() => {
        let intervalId:any = null;
        intervalId = setInterval(() => {
            getHealthApi().then((res) => {
                setOnline(res.status);
            });
        }, 5 * 1000);
        return () => {
            !!intervalId && clearInterval(intervalId);
        }
    }, []);

    const userMenu = () => (
        <Menu style={{minWidth: "14em"}}>
            <Menu.Item key={'profile'}>
                <NavLink to='/profile'>
                    {t('nav.profile')}
                </NavLink>
            </Menu.Item>
            <Menu.Divider />
            {
                !config.isSupplyChainAIPlatform && <>
                    <Menu.Item key={'official_website'}>
                        <a href={i18n.resolvedLanguage === 'en'? "https://convect.ai": "https://convect.cn"} target="_blank">
                            {t('nav.official_website')}
                        </a>
                    </Menu.Item>
                    <Menu.Divider />
                </>
            }
            <Menu.Item key={'change_language'}>
                <Language />
            </Menu.Item>
            {
                props.account_info?.is_superuser && <>
                    <Menu.Divider />
                    <Menu.Item key={'system_manage'}>
                        <NavLink to='/system_manage' target="_blank">
                            {t('nav.system_manage')}
                        </NavLink>
                    </Menu.Item>
                </>
            }
            <Menu.Divider />
            <Menu.Item key={'logout'} onClick={AuthService.logout}>
                {t('common.logout')}
            </Menu.Item>
        </Menu>
    )

    const appMenu = () => (
        <Menu style={{minWidth: "12em"}}>
            <Menu.Item key={'home'}>
                <NavLink to='/'>
                    {t('nav.home')}
                </NavLink>
            </Menu.Item>
            <Menu.Divider />
            {
                props.apps && Object.keys(props.apps).length > 0 ? Object.values(props.apps).map((app: any) => (
                    <Menu.Item key={`app-${app.id}`}>
                        <NavLink to={`/app/${app.id}/workbench/active`}>
                            {i18n.resolvedLanguage === 'en' ? app.display_name.en : app.display_name.zh}
                        </NavLink>
                    </Menu.Item>
                )) : <Menu.Item key={'empty-app-list'} disabled>
                    {t('nav.empty_app_list')}
                </Menu.Item>
            }
        </Menu>
    )

    const appHelpMenu = () => (
        <Menu style={{minWidth: "12em"}}>
            <Menu.Item key={'help'}>
                <a target='_blank' href={`${config.api_baseurl}${config.server_doc_url}`} rel="noreferrer">
                    {t('nav.help')}
                </a>
            </Menu.Item>
            <Menu.Divider />
            {
                currentApp? <Menu.Item key={`app-help-${currentApp.id}`}>
                    <a target='_blank' href={currentApp.doc_url[i18n.resolvedLanguage]} rel="noreferrer">
                        {i18n.resolvedLanguage === 'en' ? currentApp.display_name.en : currentApp.display_name.zh}
                        { `${i18n.resolvedLanguage === 'en' ? ' ' : ''}${t('nav.docs')}` }
                    </a>
                </Menu.Item> : Object.values(props.apps).map((app: any) => (
                    <Menu.Item key={`app-help-${app.id}`}>
                        <a target='_blank' href={app.doc_url[i18n.resolvedLanguage]} rel="noreferrer">
                            {i18n.resolvedLanguage === 'en' ? app.display_name.en : app.display_name.zh}
                            { `${i18n.resolvedLanguage === 'en' ? ' ' : ''}${t('nav.docs')}` }
                        </a>
                    </Menu.Item>
                ))
            }
        </Menu>
    )

    return (
        <>
            <Drawer
                width={"90%"}
                onClose={handleMenuClose}
                placement={'left'}
                open={menuState}
                closable={false}
                className='page-layout-drawer'
                style={{ body: {
                    padding: 0,
                    background: '#ffffff'
                }}}>
                <div style={{
                    display: "flex",
                    height: "100vh",
                    flexDirection: "column",
                    position: "relative"
                }}>
                    <CloseOutlined
                        onClick={handleMenuClose}
                        style={{
                          position: 'absolute',
                          top: 10,
                          right: 0,
                          padding: 10,
                          fontSize: 18
                        }}
                    />
                    <Space direction='vertical' style={{ padding: "20px 20px"}}>
                        <Typography.Text style={{ fontSize: "1.1em", fontWeight: "bold"}} ellipsis={true}>
                            {currentApp ? `${i18n.resolvedLanguage === 'en' ? currentApp.display_name.en : currentApp.display_name.zh}` : t(
                                config.isSupplyChainAIPlatform ? 'supply_chain_ai_platform.app_title' : 'app_title'
                            )}
                        </Typography.Text>
                        <Typography.Text ellipsis={true}>
                            {props.current_workspace_name}
                        </Typography.Text>
                    </Space>
                    <div style={{flex: 1, overflowY: 'auto', overflowX: 'hidden' }}>
                        <NavMenu />
                    </div>
                    <div className='menu-version'>
                        {t('common.version')}: {currentApp?.app_manifest?.version}
                    </div>
                </div>
            </Drawer>
            <Layout.Header className="header-content">
                <div className='d-flex align-items-center' style={{height: '100%'}}>
                    <NavLink to='/'>
                        {
                            config.isSupplyChainAIPlatform ? <span className='title'>
                                {t('supply_chain_ai_platform.app_title')}
                            </span> : <div className='logo-img'>
                                <Image
                                    src={LogoIcon}
                                    preview={false}
                                />
                            </div>
                        }
                    </NavLink>
                    {
                        currentApp && <div className='d-flex align-items-center' style={{maxWidth: '25vw'}}>
                            <Typography.Text className='logo' ellipsis={true}>
                                {`${i18n.resolvedLanguage === 'en' ? currentApp.display_name.en : currentApp.display_name.zh}`}
                            </Typography.Text>
                        </div>
                    }
                    <div className='d-flex align-items-center' style={{height: '100%'}}>
                        <WorkspacesManagement />
                    </div>
                </div>
                <Space className='header-button-list'>
                    {
                        !!currentApp && <HeaderSearch />
                    }
                    {
                        props.can_use_flow_assistant && <Button
                            className='vertical-hidden header-button'
                            type="text"
                            icon={
                                <Tooltip placement="top" title={t("common.assistant")}>
                                    <Image
                                        src={FlowAssistantIcon}
                                        preview={false}
                                        style={{ width: 22, height: 22, marginBottom: 2 }}
                                    />
                                </Tooltip>
                            }
                            onClick={props.openFlowAssistantModal}
                        />
                    }
                    {props.account_info?.notification_info && <Novu />}
                    <div className='d-flex align-items-center header-button'>
                        {online?
                            <Tooltip placement="top" title={t("nav.tip.network_available")}>
                                <WifiOutlined style={{fontSize: 16, color: 'green'}}/>
                            </Tooltip>:
                            <Tooltip placement="top" title={t("nav.tip.network_unavailable")}>
                                <svg t="1690183536574" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2274" width="16" height="16"><path d="M512 768c30.464 0 58.453333 10.666667 80.469333 28.416L512 896l-80.469333-99.584A127.445333 127.445333 0 0 1 512 768zM119.808 59.434667l754.218667 754.261333-60.330667 60.330667-221.013333-221.013334A255.744 255.744 0 0 0 512 640c-60.928 0-116.906667 21.290667-160.853333 56.832l-53.589334-66.389333a339.754667 339.754667 0 0 1 197.205334-75.349334L384 444.330667a468.010667 468.010667 0 0 0-166.912 86.570666L163.413333 464.512A553.984 553.984 0 0 1 316.416 376.746667L218.965333 279.253333a683.050667 683.050667 0 0 0-135.893333 85.632L29.397333 298.538667a768.768 768.768 0 0 1 125.952-82.858667L59.434667 119.808l60.373333-60.373333zM618.666667 438.826667l-97.450667-97.408L512 341.333333c132.053333 0 253.312 46.122667 348.586667 123.178667l-53.674667 66.389333a467.626667 467.626667 0 0 0-188.245333-92.074666zM512 128c182.826667 0 350.72 63.872 482.56 170.538667l-53.632 66.346666A679.808 679.808 0 0 0 512 213.333333c-37.461333 0-74.24 2.986667-110.08 8.832L329.6 149.76A771.285333 771.285333 0 0 1 512 128z" p-id="2275" fill="#d81e06"></path></svg>
                            </Tooltip>
                        }
                    </div>
                    <Dropdown placement="bottom" dropdownRender={appHelpMenu}>
                        <Button type='text' className='header-button' icon={
                            <Image
                                src={GuideIcon}
                                preview={false}
                                style={{ width: 14, height: 14, marginRight: 5 }}
                            />
                        }>
                            <span className='vertical-hidden' style={{ color: 'rgba(0,0,0,0.45)' }}>
                                {t('nav.docs')}
                            </span>
                        </Button>
                    </Dropdown>
                    <Dropdown placement="bottom" dropdownRender={appMenu}>
                        <Button type='text' shape='circle' className='header-button' icon={
                            <Image
                                src={MenuAppsIcon}
                                preview={false}
                                style={{ width: 34, height: 34}}
                            />
                        }></Button>
                    </Dropdown>
                    <Dropdown placement="bottomRight" dropdownRender={userMenu}>
                        <div className='header-button header-user-button'>
                            <Avatar
                               className='d-flex justify-content-center align-items-center'
                               icon={<UserOutlined />}
                               onError={() => true}
                            />
                            <div className="d-flex vertical-hidden header-user-name align-items-center">
                              <Typography.Text ellipsis={true}>
                                {viewUtils.prettifyUsername(AuthService.fullname())}
                              </Typography.Text>
                            </div>
                            <DownOutlined style={{ color: '#909CB2'}} className='vertical-hidden' />
                        </div>
                    </Dropdown>
                    {
                       !!currentApp && <div className='header-menu-vertical-trigger'>
                           {
                               !!menuState ?
                                   <MenuFoldOutlined className='menu-trigger' onClick={handleMenuClose} /> :
                                   <MenuUnfoldOutlined className='menu-trigger' onClick={handleMenuOpen} />
                           }
                       </div>
                   }
                </Space>
            </Layout.Header>
        </>
    );
};
const mapStateToProps = (store) => ({
  current_workspace_name: store.account.current_workspace?.name,
  apps: store.account.apps,
  account_info: store.account.account_info,
  can_use_flow_assistant: store.account.can_use_flow_assistant,
});


const mapDispatchToProps = (dispatch) => bindActionCreators({
    openFlowAssistantModal,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Header);
