import React, { useEffect, useMemo, useState, useRef } from "react";
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import ManageLayout from '../components/layouts/ManageLayout';
import NavHyperLink from '../components/NavHyperLink';
import {DataFrame} from "../interfaces/dataFrame";
import { getInstanceApi } from '../api/ServerApi';
import { getDf } from '../api/AppApi'
import { Form, Select, Modal, Table, Tabs, Spin, Typography, Pagination, Space, Button, Checkbox } from "antd";
import type { ColumnsType } from "antd/es/table";
import viewUtils from "../utils/viewUtils";
import { SettingOutlined } from "@ant-design/icons";

import { getConfig } from "../config/config";
const config = getConfig();

type TableColumn = {
  key: string;
  name: {
    en: string;
    zh: string;
  }
}

type TableCompare = {
  df_name: string;
  name: {
    zh: string;
    en: string;
  };
  columns: TableColumn[];
  data: TableCompareData[];
};

type TableCompareData = {
  row: number;
  status: string;
  originData: any[];
  newData: any[];
};

type FilterConfig = {
  only_changed: boolean;
  only_greater_than_0: boolean;
}

type ShowDiffConfig = {
  show_value: boolean;
  show_ratio: boolean;
  decimal_format: number;
}

const CompareTablePageSize = 20;

const CompareTableData = (props: {
  table?: TableCompare,
  filter?: FilterConfig,
  showDiff?: ShowDiffConfig,
}) => {
  const { t, i18n } = useTranslation();
  const [tableData, setTableData] = useState();
  const [pagination, setPagination] = useState<any>({ page: 1, page_size: CompareTablePageSize, total: 0 });
  const [cursor, setCursor] = useState<number | undefined>();

  const jumpToRow = (row?: number, calPage = false) => {
    setCursor(row);
    if (calPage) {
      setPagination((data: any) => ({
        ...data,
        page: parseInt(`${(row || 0) / data.page_size + 1}`),
      }));
    }
    try {
      const elem = document.getElementById("compare-table");
      if (!!elem) {
        elem.scrollTop = ((row || 0) % data.page_size) * 25.2;
      }
    } catch (e) {}
  };

  const handleLast = () => {
    (!!pagination.last || pagination.last === 0) &&
      jumpToRow(pagination.last, true);
  };

  const handleFirst = () => {
    (!!pagination.first || pagination.first === 0) &&
      jumpToRow(pagination.first, true);
  };

  const handlePre = () => {
    if (!!pagination.first || pagination.first === 0) {
      if (cursor === undefined || cursor === null) {
        jumpToRow(pagination.first, true);
      } else {
        if (
          (tableData?.data?.length || 0) > 0 &&
          (cursor || 0) > pagination.first
        ) {
          for (let i = (cursor || 0) - 1; i >= pagination.first; i--) {
            if (tableData?.data[i].status !== "nochange") {
              jumpToRow(i, true);
              break;
            }
          }
        }
      }
    }
  };

  const handleNext = () => {
    if (!!pagination.last || pagination.last === 0) {
      if (cursor === undefined || cursor === null) {
        jumpToRow(pagination.first, true);
      } else {
        if (
          (tableData?.data?.length || 0) > 0 &&
          (cursor || 0) < pagination.last
        ) {
          for (let i = (cursor || 0) + 1; i <= pagination.last; i++) {
            if (tableData?.data[i].status !== "nochange") {
              jumpToRow(i, true);
              break;
            }
          }
        }
      }
    }
  };

  useEffect(() => {
    let first = null,
      last = null;
    if ((tableData?.data?.length || 0) > 0) {
      for (let i = 0, len = tableData?.data?.length || 0; i < len; i++) {
        if (tableData?.data[i].status !== "nochange") {
          first = i;
          break;
        }
      }
      if (!!first || first === 0) {
        for (let i = tableData?.data?.length || 1; i > 0; i--) {
          if (tableData?.data[i - 1].status !== "nochange") {
            last = i - 1;
            break;
          }
        }
      }
    }
    setPagination({
      page: 1,
      page_size: CompareTablePageSize,
      total: tableData?.data?.length || 0,
      first: first,
      last: last,
    });
    jumpToRow(undefined);
  }, [tableData]);

  useEffect(() => {
    if(props.filter?.only_changed || props.filter?.only_greater_than_0) {
      let data = [];
      const originData = props.table?.data || [];
      for(let i = 0, len = originData.length ; i < len ; i++) {
        let row = originData[i];
        if(props.filter?.only_changed && row.status === 'nochange') {
          continue;
        }
        if(props.filter?.only_greater_than_0 && row.status === 'nochange') {
          if(
            row.newData.filter(item => typeof item === 'number').length > 0 &&
            row.newData.filter(item => typeof item === 'number' && Number(item) > 0).length < 1
          ) {
            continue;
          }
        }
        data.push(row);
      }
      setTableData({
        ...props.table,
        data: data
      });
    } else {
      setTableData(props.table);
    }
  }, [props.table, props.filter])

  const handlePageChange = (page: number, page_size: number) => {
    setPagination((data: any) => ({ ...data, page: page, page_size: page_size }));
    jumpToRow((page - 1) * page_size);
  };

  const renderCell = (field: TableField, value: any, originValue: any, table: string) => {
    const valueIsEmpty = value === null || value === undefined;
    const originValueIsEmpty = originValue === null || originValue === undefined;

    if(valueIsEmpty) {
      return "";
    }

    let cellStatus = "";
    if(value !== originValue) {
      if(table === 'new') {
        if(originValueIsEmpty) {
          cellStatus = "cell-new";
        } else {
          cellStatus = "cell-changed";
        }
      } else {
        if(originValueIsEmpty) {
          cellStatus = "cell-delete";
        }
      }
    }

    return (
      <Typography.Text className={cellStatus}>
        {
          field.columns_type === 'number' ?
            viewUtils.decimalFormat(value, props.showDiff?.decimal_format || 2) :
            field.columns_type === 'boolean' ? (!!value ? 'True' : 'False') : `${value}`
        }
        {
          !!props.showDiff?.show_value && viewUtils.showDiffValue(
            value, table === 'new' ? originValue : null
          )
        }
        {
          !!props.showDiff?.show_ratio && viewUtils.showDiffRatio(
            value, table === 'new' ? originValue : null
          )
        }
      </Typography.Text>
    );
  };

  const columns = (key = "origin") => {
    return [
      {
        key: "status",
        align: "center",
        fixed: "left",
        render: (r: TableCompareData) =>
          r.status === "delete" && key === "origin"
            ? "-"
            : r.status === "new" && key === "new"
            ? "+"
            : "",
      },
      ...(tableData?.fields || []).map(
        (field: TableField, index: number) => ({
          key: `field-${field.key}`,
          title: (field.name || {} )[i18n.resolvedLanguage === 'zh' ? 'zh' :'en'] || field.key || '',
          render: (r: TableCompareData) => renderCell(
            field,
            r[key === "origin" ? "originData" : "newData"][index],
            r[key === "origin" ? "newData" : "originData"][index],
            key
          ),
        })
      ),
    ] as ColumnsType<any>;
  };

  return (
    <>
      <div
        id="compare-table"
        style={{
          display: "flex",
          maxHeight: `calc(100vh - 220px ${!!config.IsSimpleLayout ? '+ 30px' : ''})`,
          overflowY: "auto",
          border: "1px solid #dddddd",
        }}
      >
        <Table
          loading={!tableData}
          className="compare-table"
          style={{ width: "50%" }}
          size="small"
          rowKey={(r: TableCompareData) => r.row}
          columns={columns("origin")}
          dataSource={tableData?.data || []}
          scroll={{
            x: "max-content",
            scrollToFirstRowOnChange: true,
          }}
          rowClassName={(r) =>
            `row-${r.status} ${
              cursor === r.row && r.status !== "nochange" ? "row-selected" : ""
            }`
          }
          pagination={{
            current: pagination.page,
            pageSize: pagination.page_size,
            hideOnSinglePage: true,
            showSizeChanger: false,
          }}
        />
        <Table
          loading={!tableData}
          className="compare-table compare-new-table"
          style={{ width: "50%" }}
          size="small"
          rowKey={(r: TableCompareData) => r.row}
          columns={columns("new")}
          dataSource={tableData?.data || []}
          scroll={{
            x: "max-content",
            scrollToFirstRowOnChange: true,
          }}
          rowClassName={(r) =>
            `row-${r.status} ${
              cursor === r.row && r.status !== "nochange" ? "row-selected" : ""
            }`
          }
          pagination={{
            current: pagination.page,
            pageSize: pagination.page_size,
            hideOnSinglePage: true,
            showSizeChanger: false,
          }}
        />
      </div>
      <div
        style={{
          border: "1px solid #dddddd",
          borderTop: "none",
          width: "100%",
          height: "45px",
          marginBottom: "-12px",
          display: "flex",
          alignItems: "center",
          padding: "0 10px",
          justifyContent: "space-between",
          position: "relative",
          background: 'white'
        }}
      >
        <Space>
          <Button
            size="small"
            type="primary"
            style={{ color: "#52c41a", background: "#e6ffec", border: 'none' }}
          >
            {t('common.new')}
          </Button>
          <Button
            size="small"
            type="primary"
            style={{ color: "#ff4d4f", background: "#ffebe9", border: 'none' }}
          >
            {t('common.delete')}
          </Button>
          <Button
            size="small"
            type="primary"
            style={{ color: "#faad14", background: "#fffbe6", border: 'none' }}
          >
            {t('common.change')}
          </Button>
        </Space>
        <Space>
          <Button
            onClick={handleFirst}
            disabled={
              (!pagination.first && pagination.first !== 0) ||
              ((!!cursor || cursor === 0) && (cursor || 0) <= pagination.first)
            }
          >
            {t('common.first')}
          </Button>
          <Button
            onClick={handlePre}
            disabled={
              (!pagination.first && pagination.first !== 0) ||
              ((!!cursor || cursor === 0) && (cursor || 0) <= pagination.first)
            }
          >
            {t('common.previous')}
          </Button>
          <Button
            onClick={handleNext}
            disabled={
              (!pagination.last && pagination.last !== 0) ||
              ((!!cursor || cursor === 0) && (cursor || 0) >= pagination.last)
            }
          >
            {t('common.next')}
          </Button>
          <Button
            onClick={handleLast}
            disabled={
              (!pagination.last && pagination.last !== 0) ||
              ((!!cursor || cursor === 0) && (cursor || 0) >= pagination.last)
            }
          >
            {t('common.last')}
          </Button>
        </Space>
        <Pagination
          size="small"
          showTotal={(total) => t("common.total", { count: total })}
          pageSize={pagination.page_size}
          hideOnSinglePage={false}
          showSizeChanger={true}
          pageSizeOptions={[10, 20, 50]}
          current={pagination.page}
          total={pagination.total}
          onChange={handlePageChange}
        />
      </div>
    </>
  );
};

const RunInstanceComparePage = (props: PropsWithRef<any>) => {
  const { t, i18n } = useTranslation();
  const { app_id, instances, tables } = useParams();
  const currentTableIdRef: any = useRef();
  const compareDataRef: any = useRef({});
  const [instanceInfo, setInstanceInfo] = useState([]);
  const [currentTable, setCurrentTable] = useState<TableCompare | undefined>();
  const [tableList, setTableList] = useState([]);
  const [filter, setFilter] = useState({
    only_changed: false,
    only_greater_than_0: false,
  });
  const [showDiff, setShowDiff] = useState({
    show_value: false,
    show_ratio: false,
    decimal_format: 2,
  });

  useEffect(() => {
    const only_changed = window.localStorage.getItem("compare_only_changed");
    const only_greater_than_0 = window.localStorage.getItem("compare_only_greater_than_0");
    if(only_changed || only_greater_than_0) {
      setFilter({
        only_changed: !!only_changed,
        only_greater_than_0: !!only_greater_than_0,
      })
    }
    const show_diff_value = window.localStorage.getItem("compare_show_diff_value");
    const show_diff_ratio = window.localStorage.getItem("compare_show_diff_ratio");
    const decimal_format = window.localStorage.getItem("compare_decimal_format");
    if(show_diff_value || show_diff_ratio || decimal_format) {
      setShowDiff({
        show_value: !!show_diff_value,
        show_ratio: !!show_diff_ratio,
        decimal_format: parseInt(decimal_format) === 0 ? 0 : (decimal_format|| 2)
      })
    }
  }, [])

  useEffect(() => {
    (async() => {
      const instanceIds = instances?.split(" ");
      if(!!instanceIds && instanceIds.length > 1) {
        const oldInstance = await getInstanceApi({instance_id: instanceIds[0] })
        const newInstance = await getInstanceApi({instance_id: instanceIds[1] })
        setInstanceInfo([
          oldInstance?.data,
          newInstance?.data,
        ])
      }
    })()
  }, [instances])

  const calCompareTableData = (df_name, originTable: DataFrame, table: DataFrame) => {
    let fields = {};
    for(let i = 0, len = (originTable?.meta?.columns || []).length; i < len; i++){
      let column = (originTable?.meta?.columns || [])[i];
      fields[`column_${column}`] = {
        key: column,
        name: (originTable?.meta?.columns_locale || {})[column],
        columns_type: (originTable?.meta?.columns_type || [])[i] || 'string',
        originSource: i
      };
    }
    for(let i = 0, len = (table?.meta?.columns || []).length; i < len; i++){
      let column = (table?.meta?.columns || [])[i];
      if(!fields[`column_${column}`]) {
        fields[`column_${column}`] = {
          key: column,
          name: (table?.meta?.columns_locale || {})[column],
          columns_type: (table?.meta?.columns_type || [])[i] || 'string',
          newSource: i
        };
      } else {
        fields[`column_${column}`].newSource = i;
      }
    }
    const TableEmptyFields = Object.values(fields).reduce((ret, item, index) => {
      if(!item.originSource && item.originSource !== 0) {
        ret.origin.push(index);
      }
      if(!item.newSource && item.newSource !== 0) {
        ret.new.push(index);
      }
      return ret;
    }, {origin: [], new: []});

    let result: TableCompare = {
      df_name: df_name,
      name: table?.meta?.name || {},
      fields: Object.values(fields),
      data: [],
    };
    let OriginData = [],
      NewData = [];
    for (
      let i = 0,
        olen = originTable.data.length,
        len = table.data.length,
        maxLen = Math.max(originTable.data.length, table.data.length);
      i < maxLen;
      i++
    ) {
      if (i < olen) {
        if(!originTable?.meta?.with_summary_row || i < olen - 1) {
          let row = [
            ...(
              originTable?.meta?.num_index_column === 1 ?
                [originTable.index[i]] :
                originTable.index[i]
            ),
            ...originTable.data[i]
          ];
          (TableEmptyFields.origin || []).forEach(index => {
            row.splice(index, 0, null);
          })
          OriginData.push(row);
        }
      }
      if (i < len) {
        if(!table?.meta?.with_summary_row || i < len - 1) {
          let row = [
            ...(
              table?.meta?.num_index_column === 1 ?
                [table.index[i]] :
                table.index[i]
            ),
            ...table.data[i]
          ];
          (TableEmptyFields.new || []).forEach(index => {
            row.splice(index, 0, null);
          })
          NewData.push(row);
        }
      }
    }
    const compareRowData = (orginData: any, newData: any) => {
      if (newData.join("") === orginData.join("")) {
        return "same";
      } else {
        for (
          let celli = 0, cellLen = newData.length;
          celli < cellLen;
          celli++
        ) {
          if (newData[celli] > orginData[celli]) {
            return "delete";
          } else if (newData[celli] < orginData[celli]) {
            return "new";
          }
        }
      }
    };
    OriginData = OriginData.sort((a: any, b: any) =>
      compareRowData(a, b) === "new" ? 1 : -1
    );
    NewData = NewData.sort((a: any, b: any) =>
      compareRowData(a, b) === "new" ? 1 : -1
    );
    if(!!originTable?.meta?.with_summary_row) {
      OriginData.push(
        [
          ...(
            originTable?.meta?.num_index_column === 1 ?
              [originTable.index[originTable?.meta?.num_row - 1]] :
              originTable.index[originTable?.meta?.num_row - 1]
          ),
          ...originTable.data[originTable?.meta?.num_row - 1]
        ]
      );
    }
    if(!!table?.meta?.with_summary_row) {
      NewData.push(
        [
          ...(
            table?.meta?.num_index_column === 1 ?
              [table.index[table?.meta?.num_row - 1]] :
              table.index[table?.meta?.num_row - 1]
          ),
          ...table.data[table?.meta?.num_row - 1]
        ]
      );
    }
    let i = 0,
      oi = 0,
      ni = 0,
      compareFinish = false;
    while (!compareFinish) {
      if (oi < originTable.data.length && ni < table.data.length) {
        const ret = compareRowData(OriginData[oi], NewData[ni]);
        if (ret === "same") {
          result.data.push({
            row: i,
            status: "nochange",
            originData: OriginData[oi],
            newData: NewData[ni],
          });
          i++;
          oi++;
          ni++;
        } else if (ret === "delete") {
          if (i > 0 && result.data[i - 1].status === "new") {
            result.data[i - 1].status = "changed";
            result.data[i - 1].originData = OriginData[oi];
          } else {
            result.data.push({
              row: i,
              status: "delete",
              originData: OriginData[oi],
              newData: [],
            });
            i++;
          }
          oi++;
        } else {
          if (i > 0 && result.data[i - 1].status === "delete") {
            result.data[i - 1].status = "changed";
            result.data[i - 1].newData = NewData[ni];
          } else {
            result.data.push({
              row: i,
              status: "new",
              originData: [],
              newData: NewData[ni],
            });
            i++;
          }
          ni++;
        }
      } else if (oi >= originTable.data.length && ni < table.data.length) {
        for (
          let forindex = 0, len = table.data.length;
          ni < len;
          ni++, i++, forindex++
        ) {
          if (
            forindex === 0 &&
            i > 0 &&
            result.data[i - 1].status === "delete"
          ) {
            result.data[i - 1].status = "changed";
            result.data[i - 1].newData = NewData[ni];
            i--;
          } else {
            result.data.push({
              row: i,
              status: "new",
              originData: [],
              newData: NewData[ni],
            });
          }
        }
      } else {
        for (
          let forindex = 0, olen = originTable.data.length;
          oi < olen;
          oi++, i++, forindex++
        ) {
          if (forindex === 0 && i > 0 && result.data[i - 1].status === "new") {
            result.data[i - 1].status = "changed";
            result.data[i - 1].originData = OriginData[oi];
            i--;
          } else {
            result.data.push({
              row: i,
              status: "delete",
              originData: OriginData[oi],
              newData: [],
            });
          }
        }
      }
      if (oi >= originTable.data.length && ni >= table.data.length) {
        compareFinish = true;
      }
    }
    return result;
  };

  useEffect(() => {
    const instanceIds = instances?.split(" ");
    const tableIds = tables?.split(" ");
    const apiServer = props.apps[app_id]?.api;
    if (
      !!apiServer &&
      !!instanceIds &&
      instanceIds.length > 1 &&
      !!tableIds &&
      tableIds.length > 0
    ) {
      currentTableIdRef.current = tableIds[0];
      compareDataRef.current = {};
      setTableList([]);
      setCurrentTable(undefined);
      for (let tableindex = 0; tableindex < tableIds.length; tableindex++) {
        getDf(apiServer,
          { instance_id: instanceIds[0], df_name: tableIds[tableindex] }
        ).then((originRes) => {
          if(originRes?.status) {
            return getDf(apiServer,
              { instance_id: instanceIds[1], df_name: tableIds[tableindex] }
            ).then((res) => {
              if (res?.status) {
                compareDataRef.current[`table-${tableIds[tableindex]}`] =
                  calCompareTableData(tableIds[tableindex], originRes.data, res.data);
                setTableList(
                  Object.values(compareDataRef.current)
                    .map((table: any) => ({
                      df_name: table?.df_name || 0,
                      name: table?.name || {},
                    }))
                    .sort(
                      (a: any, b: any) =>
                        tableIds.indexOf(`${a.df_name}`) -
                        tableIds.indexOf(`${b.df_name}`)
                    )
                );
                if (
                  !!currentTableIdRef.current &&
                  currentTableIdRef.current === tableIds[tableindex]
                ) {
                  setCurrentTable(
                    compareDataRef.current[`table-${currentTableIdRef.current}`]
                  );
                }
              }
            });
          }
        });
      }
    }
  }, [props.apps, app_id, instances, tables]);

  const handleTabChange = (df_name: string) => {
    currentTableIdRef.current = df_name;
    setCurrentTable(
      compareDataRef.current[`table-${df_name}`] || { df_name: df_name }
    );
  };

  const renderCurrentTableData = useMemo(
    () => <CompareTableData
      table={currentTable}
      filter={filter}
      showDiff={showDiff}
    />,
    [currentTable, filter, showDiff]
  );

  const ConfigModalButton = () => {
    const [show, setShow] = useState(false);
    const [form] = Form.useForm();

    const openModal = () => setShow(true);
    const closeModal = () => setShow(false);

    useEffect(() => {
      form.setFieldsValue({
        only_changed: filter.only_changed,
        only_greater_than_0: filter.only_greater_than_0,
        show_value: showDiff.show_value,
        show_ratio: showDiff.show_ratio,
        decimal_format: showDiff.decimal_format
      })
    }, [show, form])

    const handleSubmit = () => {
      const data  = form.getFieldsValue();
      let filterRet = {};
      let showDiffRet = {};
      if(data?.only_changed !== filter.only_changed) {
        if(!!data?.only_changed) {
          window.localStorage.setItem("compare_only_changed", true);
        } else {
          window.localStorage.removeItem("compare_only_changed")
        }
        filterRet.only_changed = !!data?.only_changed;
      }
      if(data?.only_greater_than_0 !== filter.only_greater_than_0) {
        if(!!data?.only_greater_than_0) {
          window.localStorage.setItem("compare_only_greater_than_0", true);
        } else {
          window.localStorage.removeItem("compare_only_greater_than_0")
        }
        filterRet.only_greater_than_0 = !!data?.only_greater_than_0;
      }
      if(data?.show_value !== showDiff.show_value) {
        if(!!data?.show_value) {
          window.localStorage.setItem("compare_show_diff_value", true);
        } else {
          window.localStorage.removeItem("compare_show_diff_value")
        }
        showDiffRet.show_value = !!data?.show_value;

      }
      if(data?.show_ratio !== showDiff.show_ratio) {
        if(!!data?.show_ratio) {
          window.localStorage.setItem("compare_show_diff_ratio", true);
        } else {
          window.localStorage.removeItem("compare_show_diff_ratio")
        }
        showDiffRet.show_ratio = !!data?.show_ratio;
      }
      if(data?.decimal_format !== showDiff.decimal_format) {
        const decimal_format = parseInt(data?.decimal_format) || 2;
        window.localStorage.setItem("compare_decimal_format", decimal_format);
        showDiffRet.decimal_format = decimal_format;
      }
      if(Object.keys(filterRet).length > 0) {
        setFilter(data => ({ ...data, ...filterRet }));
      }
      if(Object.keys(showDiffRet).length > 0) {
        setShowDiff(data => ({ ...data, ...showDiffRet }))
      }
      closeModal();
    }

    return (
      <>
        <Modal
          centered
          title={t('common.settings')}
          open={show}
          onCancel={closeModal}
          onOk={handleSubmit}
          cancelText={t('common.cancel')}
          okText={t('common.comfirm')}
        >
          <Form name='compare config form' form={form}>
            <Form.Item name='only_changed' style={{marginBottom: 0}} valuePropName="checked">
              <Checkbox>{t('instance.action.only_changed')}</Checkbox>
            </Form.Item>
            <Form.Item name='only_greater_than_0' style={{marginBottom: 0}} valuePropName="checked">
              <Checkbox>{t('instance.action.only_greater_than_0')}</Checkbox>
            </Form.Item>
            <Form.Item name='show_value' style={{marginBottom: 0}} valuePropName="checked">
                <Checkbox>{t('instance.action.show_diff_value')}</Checkbox>
            </Form.Item>
            <Form.Item name='show_ratio' style={{marginBottom: 0}} valuePropName="checked">
              <Checkbox>{t('instance.action.show_diff_ratio')}</Checkbox>
            </Form.Item>
            <Form.Item
              name='decimal_format'
              label={t("sheet.decimal_format")}
              style={{ marginTop: '0.5em'}}>
              <Select style={{ width: '10em' }} options={new Array(10).fill(null).map((item, index) => ({
                value: index,
                label: index,
              }))} />
            </Form.Item>
          </Form>
        </Modal>
        <Button
          className='d-flex justify-content-center align-items-center'
          icon={<SettingOutlined />}
          onClick={openModal}
        />
      </>
    )
  }

  return (
    <>
      <ManageLayout>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
          <div style={{ flex: 1, overflow: 'hidden', minWidth: '50%' }}>
            <NavHyperLink showBack={true} routes={[
              {
                  path: '#',
                  breadcrumbName: `${t('instance.action.compare_title')}( ${instanceInfo[0]?.name || ''} vs ${instanceInfo[1]?.name || ''} )`,
              }
            ]}/>
          </div>
          <ConfigModalButton />
          {/*<div>
            <Checkbox checked={filter.only_changed} onChange={handleChangeFilterOnlyChanged}>
              {t('instance.action.only_changed')}
            </Checkbox>
            <Checkbox checked={filter.only_greater_than_0} onChange={handleChangeFilterOnlyGreaterZero}>
              {t('instance.action.only_greater_than_0')}
            </Checkbox>
            <Checkbox checked={showDiff.show_value} onChange={handleChangeShowDiffValue}>
              {t('instance.action.show_diff_value')}
            </Checkbox>
            <Checkbox checked={showDiff.show_ratio} onChange={handleChangeShowDiffRatio}>
              {t('instance.action.show_diff_ratio')}
            </Checkbox>
            <Dropdown
              trigger={["click"]}
              dropdownRender={
                () => <Menu
                  selectedKeys={`${showDiff.decimal_format}`}
                  items={new Array(10).fill(null).map((item, index) => ({
                    key: index,
                    label: index,
                  }))}
                  onClick={handleChangeDecimalFormat}
                />
              }
            >
              <Button
                size="small"
                style={{ marginLeft: 5 }}
              >
                {`${t("sheet.decimal_format")}(${showDiff?.decimal_format})`}
              </Button>
            </Dropdown>
          </div>*/}
        </div>
        {tableList.length > 0 ? (
          <>
            <Tabs
              onTabClick={handleTabChange}
              activeKey={`${currentTable?.df_name}`}
              items={tableList.map((table) => ({
                label: table.name[i18n.resolvedLanguage === 'zh' ? 'zh' :'en'] || table.df_name || "",
                key: `${table.df_name}`,
              }))}
            />
            {renderCurrentTableData}
          </>
        ) : (
          <div style={{ textAlign: "center", padding: "20vh 0" }}>
            <Spin />
          </div>
        )}
      </ManageLayout>
    </>
  );
};

const mapStateToProps = (store) => ({
  apps: store.account.apps,
})

export default connect(mapStateToProps, {})(RunInstanceComparePage);