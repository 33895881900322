import React, { useState, useEffect, useRef } from "react";
import viewUtils from '../../utils/viewUtils';
import { useParams } from "react-router-dom";
import { getDf } from '../../api/AppApi';
import { connect } from 'react-redux';

import Heatmap from './Heatmap/index.tsx';
import LocationRelationship from './LocationRelationship/index.tsx';
import PieChart from './PieChart';
import DonutChart from './DonutChart';
import LineChart from "./LineChart";
import ColumnChart from "./ColumnChart";
import BarChart from "./BarChart";
import StackedColumnChart from "./StackedColumnChart";
import StackedBarChart from "./StackedBarChart";
import StackedLineChart from "./StackedLineChart";
import PercentageColumnChart from "./PercentageColumnChart";
import PercentageBarChart from "./PercentageBarChart";

type Props = {
    apps: any;
    id: string;
    chart_view: any;
};

const ChartViewComponent = React.memo((props: Props) => {
    const [tableData, setTableData] = useState();
    const params = useParams();
    const currentFiltersRef: any = useRef();

    useEffect(() => {
        const controller = new AbortController();
        if (
            !!params.app_id &&
            props.chart_view &&
            props.chart_view?.run_instance_id &&
            props.chart_view?.dataframe &&
            tableData?.df_name !== props.chart_view?.dataframe
        ) {
            getDf(props.apps[params?.app_id]?.api, {
                instance_id: props.chart_view?.run_instance_id,
                df_name: Array.isArray(props.chart_view?.dataframe) ?
                    props.chart_view?.dataframe[1] :
                    props.chart_view?.dataframe
            }, { signal: controller.signal }).then((res) => {
                if (res.status) {
                    setTableData(
                        viewUtils.filterDfData(
                            viewUtils.formatDfData(
                                props.chart_view?.dataframe,
                                res.data,
                                true,
                            ),
                            props.chart_view.filters
                        )
                    )
                }
            }).catch((error) => console.log(error))
        } else if (
            !!tableData &&
            JSON.stringify(props.chart_view.filters) !== JSON.stringify(currentFiltersRef.current)
        ) {
            setTableData(data => (
                viewUtils.filterDfData(
                    data,
                    props.chart_view.filters
                )
            ))
        }
        currentFiltersRef.current = props.chart_view?.filters;
        return () => {
            controller.abort();
        }
    }, [
        props.chart_view,
        props.apps,
        tableData,
        params?.app_id
    ])

    if (!props.chart_view?.dataframe || props.chart_view?.dataframe !== tableData?.df_name) {
        return null;
    }

    if (props.chart_view?.chart_type === 'COLUMN_CHART') {
        return <ColumnChart
            id={props.id}
            chart_view={props.chart_view}
            table_data={tableData}
        />
    }

    if (props.chart_view?.chart_type === 'STACKED_COLUMN_CHART') {
        return <StackedColumnChart
            id={props.id}
            chart_view={props.chart_view}
            table_data={tableData}
        />
    }

    if (props.chart_view?.chart_type === 'PERCENTAGE_COLUMN_CHART') {
        return <PercentageColumnChart
            id={props.id}
            chart_view={props.chart_view}
            table_data={tableData}
        />
    }

    if (props.chart_view?.chart_type === 'BAR_CHART') {
        return <BarChart
            id={props.id}
            chart_view={props.chart_view}
            table_data={tableData}
        />
    }

    if (props.chart_view?.chart_type === 'STACKED_BAR_CHART') {
        return <StackedBarChart
            id={props.id}
            chart_view={props.chart_view}
            table_data={tableData}
        />
    }

    if (props.chart_view?.chart_type === 'PERCENTAGE_BAR_CHART') {
        return <PercentageBarChart
            id={props.id}
            chart_view={props.chart_view}
            table_data={tableData}
        />
    }

    if (props.chart_view?.chart_type === 'PIE_CHART') {
        return <PieChart
            id={props.id}
            chart_view={props.chart_view}
            table_data={tableData}
        />
    }

    if (props.chart_view?.chart_type === 'DONUT_CHART') {
        return <DonutChart
            id={props.id}
            chart_view={props.chart_view}
            table_data={tableData}
        />
    }

    if (props.chart_view?.chart_type === 'LINE_CHART') {
        return <LineChart
            id={props.id}
            chart_view={props.chart_view}
            table_data={tableData}
        />
    }

    if (props.chart_view?.chart_type === 'STACKED_LINE_CHART') {
        return <StackedLineChart
            id={props.id}
            chart_view={props.chart_view}
            table_data={tableData}
        />
    }

    if (props.chart_view?.chart_type === 'GAODE_HEATMAP') {
        return <Heatmap
            id={props.id}
            chart_view={props.chart_view}
            table_data={tableData}
        />
    }

    if (props.chart_view?.chart_type === 'GAODE_LOCATION_RELATIONSHIP') {
        return <LocationRelationship
            id={props.id}
            chart_view={props.chart_view}
            table_data={tableData}
        />
    }

    return null;
}, (prev, next) => {
    return prev.id === next.id &&
        JSON.stringify(prev.chart_view) === JSON.stringify(next.chart_view);
})

const mapStateToProps = (store) => ({
    apps: store.account.apps,
})

export default connect(mapStateToProps, {})(ChartViewComponent);