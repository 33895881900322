
export function getConfig() {

  let ssoConfig = {
    isYiliSso: false,
    authUrl: process.env.REACT_APP_TEST_AUTH_URL,
    authRealm: process.env.REACT_APP_AUTH_REALM,
    authYiliLogoutUrl: null,
    authIdpHint: null,
  } //default dev

  if([
    'https://flowopt.ain.digitalyili.com',
    'https://flowopt.ain-test.digitalyili.com',
  ].includes(window?.location?.origin)) {
    const IsYiliUatSso = window?.location?.origin?.includes('ain-test');
    ssoConfig = {
      ...ssoConfig,
      isYiliSso: true,
      authUrl: process.env.REACT_APP_AUTH_URL,
      authRealm: IsYiliUatSso ? 'yili-uat' : 'yili',
      authYiliLogoutUrl: IsYiliUatSso ?
        'https://iam-uat.dctest.digitalyili.com/idp/profile/OAUTH2/Redirect/GLO?redirectToLogin=true&entityID=flowsit' :
        'https://iam.digitalyili.com/idp/profile/OAUTH2/Redirect/GLO?redirectToLogin=true&entityID=flowprod',
      authIdpHint: IsYiliUatSso ? 'convect-yili-iam-uat' : 'convect-yili-iam'
    }
  } else if ([
    'https://flow.convect.ai',
    'https://flow.convect.cn',
  ].includes(window?.location?.origin)) {
    ssoConfig = {
      ...ssoConfig,
      authUrl: process.env.REACT_APP_AUTH_URL,
    }
  }

  const disableLLMHosts = process.env.REACT_APP_DISABLE_LLM_HOSTS?.split(",");
  const simpleLayoutHosts = process.env.REACT_APP_SIMPLE_LAYOUT_HOSTS?.split(",")
  const IsSimpleLayout = process.env.REACT_APP_MODE === 'simple-layout' || (simpleLayoutHosts.length > 0 && !!simpleLayoutHosts.find(
      key => !!key && !!window?.location?.origin?.includes(key)
    ))

  return {
    version: process.env.REACT_APP_VERSION,
    IsSimpleLayout: IsSimpleLayout,
    api_baseurl: process.env.NODE_ENV === 'development' ? (
      process.env.REACT_APP_API_BASE_URL || process.env.REACT_APP_DEFAULT_API_BASE_URL
    ) : "",
    server_api_url: process.env.REACT_APP_SERVER_API_URL,
    server_doc_url: process.env.REACT_APP_SERVER_DOC_URL,
    IsYiliSso: ssoConfig.isYiliSso,
    auth_url: ssoConfig.authUrl,
    auth_realm: ssoConfig.authRealm || "",
    auth_client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
    authYiliLogoutUrl: ssoConfig.authYiliLogoutUrl,
    auth_idp_hint: ssoConfig.authIdpHint,
    llm_url: process.env.REACT_APP_LLM_URL,
    disable_llm: disableLLMHosts.length > 0 && !!disableLLMHosts.find(
      key => !!key && !!window?.location?.origin?.includes(key)
    ),
    isSupplyChainAIPlatform: !!window?.location?.origin?.includes("app.supplychainai.cc"),
  };
}
