import React, { useEffect, useCallback, useRef } from "react";
import * as d3 from 'd3';
import { useTranslation } from 'react-i18next';
import { Form, Select, InputNumber, Radio, Collapse, Checkbox } from 'antd';
import { ChartView } from '../../interfaces/chartView';
import { colorTheme } from "./ColorTheme";
import useWindowSize from '../../utils/useWindowSize';

const { Panel } = Collapse;
const { Option } = Select;

type Props = {
    id: string;
    chart_view: any;
    table_data: any;
};

const BarChart = (props: Props) => {
    const { t, i18n } = useTranslation();
    const windowSize = useWindowSize({id: `bar-chart-${props.id}`});
    const textSize = props.chart_view?.chart_config?.text_size || 12;
    const tooltipRef = useRef();

    const drawChart = useCallback(() => {
        const countBy = props.chart_view?.chart_config?.count_by
        const sortBy = props.chart_view?.chart_config?.sort_by
        const sortRule = props.chart_view?.chart_config?.sort_rule
        const operation = props.chart_view?.chart_config?.operation
        const color = props.chart_view?.chart_config?.color

        const dimensionKey = props.chart_view?.chart_config?.dimension
        const stackedByKey = props.chart_view?.chart_config?.stacked_by
        const valueKey = props.chart_view?.chart_config?.value

        const rawData = (props.table_data?.filtered_data || [])
        const dimensionValues = Array.from(new Set(rawData.map((item: any) => item[dimensionKey])));
        const stackedByValues = Array.from(new Set(rawData.map((item: any) => item[stackedByKey]))).sort();

        const data: any[] = [];
        const sumData: any = {};
        for (const dimensionValue of dimensionValues) {
            const record: any = { _count: 0 }
            record[dimensionKey] = dimensionValue
            sumData[dimensionValue as string] = 0
            for (const stackedByValue of stackedByValues) {
                record[stackedByValue as string] = 0
            }
            data.push(record)
        }
        for (const item of rawData) {
            const record = data.find(e => {
                return e[dimensionKey] === item[dimensionKey]
            })
            if (countBy === "count_records") {
                record[item[stackedByKey]] += 1
                sumData[item[dimensionKey]] += 1
            } else {
                if (stackedByKey) {
                    record[item[stackedByKey]] += item[valueKey]
                } else {
                    if (operation === "sum") {
                        record[item[stackedByKey]] += item[valueKey]
                        sumData[item[dimensionKey]] += item[valueKey]
                    } else if (operation === "min") {
                        if (record.firstCompared) {
                            record[item[stackedByKey]] = Math.min(record[item[stackedByKey]], item[valueKey])
                        } else {
                            record[item[stackedByKey]] = item[valueKey]
                            record.firstCompared = true
                        }
                        sumData[item[dimensionKey]] = record[item[stackedByKey]]
                    } else if (operation === "max") {
                        if (record.firstCompared) {
                            record[item[stackedByKey]] = Math.max(record[item[stackedByKey]], item[valueKey])
                        } else {
                            record[item[stackedByKey]] = item[valueKey]
                            record.firstCompared = true
                        }
                        sumData[item[dimensionKey]] = record[item[stackedByKey]]
                    } else if (operation === "average") {
                        record[item[stackedByKey]] += item[valueKey]
                        record.valueField = item[stackedByKey]
                        record._count += 1
                        sumData[item[dimensionKey]] += item[valueKey]
                    }
                }
            }
        }
        if (operation === "average") {
            for (const item of data) {
                item[item.valueField] /= item._count
            }
        }

        if (sortBy === 'x') {
            if (sortRule === 'ascending') {
                if(dimensionValues.every(item => typeof item === 'number')) {
                    dimensionValues.sort((e1: any, e2: any) => e1 - e2)
                    data.sort((e1: any, e2: any) => e1[dimensionKey] - e2[dimensionKey])
                } else {
                    dimensionValues.sort((e1: any, e2: any) => e1.toString().localeCompare(e2.toString()))
                    data.sort((e1: any, e2: any) => e1[dimensionKey].toString().localeCompare(e2[dimensionKey].toString()))
                }
            } else {
                if(dimensionValues.every(item => typeof item === 'number')) {
                    dimensionValues.sort((e2: any, e1: any) => e1 - e2)
                    data.sort((e2: any, e1: any) => e1[dimensionKey] - e2[dimensionKey])
                } else {
                    dimensionValues.sort((e2: any, e1: any) => e1.toString().localeCompare(e2.toString()))
                    data.sort((e2: any, e1: any) => e1[dimensionKey].toString().localeCompare(e2[dimensionKey].toString()))
                }
            }
        } else {
            if (sortRule === 'ascending') {
                dimensionValues.sort((e1: any, e2: any) => {
                    if (sumData[e1] === sumData[e2]) {
                        return e1.toString().localeCompare(e2.toString())
                    } else {
                        return sumData[e1] - sumData[e2]
                    }
                })
                data.sort((e1: any, e2: any) => {
                    if (sumData[e1[dimensionKey]] === sumData[e2[dimensionKey]]) {
                        return e1[dimensionKey].toString().localeCompare(e2[dimensionKey].toString())
                    } else {
                        return sumData[e1[dimensionKey]] - sumData[e2[dimensionKey]]
                    }
                })
            } else {
                dimensionValues.sort((e2: any, e1: any) => {
                    if (sumData[e1] === sumData[e2]) {
                        return e1.toString().localeCompare(e2.toString())
                    } else {
                        return sumData[e1] - sumData[e2]
                    }
                })
                data.sort((e2: any, e1: any) => {
                    if (sumData[e1[dimensionKey]] === sumData[e2[dimensionKey]]) {
                        return e1[dimensionKey].toString().localeCompare(e2[dimensionKey].toString())
                    } else {
                        return sumData[e1[dimensionKey]] - sumData[e2[dimensionKey]]
                    }
                })
            }
        }

        const formatData = (data?: number) => {
            if (!data) {
                return ''
            }
            if (countBy === "count_records") {
                return data
            } else {
                return Math.floor(data * 1000) / 1000.0
            }
        }

        const svg = d3.select(`#bar-chart-${props.id}`)
            .append("svg")
            .attr("width", "100%")
            .attr("height", "100%");

        const xDomainMax = d3.max(data.map((item) => {
            const copy = { ...item };
            delete copy[dimensionKey];
            delete copy._count;
            delete copy.firstCompared;
            return d3.max(Object.values(copy));
        })) || 0;

        let rootSize = {
            width: svg.node().getBoundingClientRect().width,
            height: svg.node().getBoundingClientRect().height
        }
        let legendSize = {
            width: stackedByKey ? Math.min(
                textSize * 11.5,
                d3.max(stackedByValues, (d) => (`${d}`.length + 1.5) * textSize)
            ) : 0,
            y: textSize
        }
        legendSize.x = rootSize.width - legendSize.width;
        let yAxisSize = {
            x0: 1.5 * textSize,
            y0: textSize * 2,
            padding: 0.2,
            ellipsis: Math.min(d3.max(dimensionValues, d => `${d}`.length) || 1, 10),
        }
        yAxisSize.x1 = yAxisSize.x0 + textSize * yAxisSize.ellipsis * 0.6 + textSize;
        let xAxisSize = {
            x0: yAxisSize.x1,
            x1: rootSize.width - legendSize.width - (
                `${xDomainMax.toLocaleString()}`.length * 0.6 + 1
            ) * textSize,
            y: rootSize.height - textSize * 4
        }
        yAxisSize.y1 = xAxisSize.y;
        rootSize.showLegend = (xAxisSize.x1 - xAxisSize.x0) > rootSize.width * 0.6;
        rootSize.showYAxis = rootSize.showLegend
        if(!rootSize.showYAxis) {
            yAxisSize.ellipsis = 0;
            yAxisSize.x1 = yAxisSize.x0 + textSize * 2;
            xAxisSize.x0 = yAxisSize.x1;
        }
        if(!rootSize.showLegend) {
            xAxisSize.x1 = xAxisSize.x1 + legendSize.width;
        }
        xAxisSize.ticks = Math.min(
            Math.floor(
                (xAxisSize.x1 - xAxisSize.x0) / (
                    `${xDomainMax.toLocaleString()}`.length * 0.6 || 1
                ) / textSize
            ) || 1, 10
        );

        const colors = d3.scaleOrdinal().range(colorTheme.find(item => {
            return item.value === color
        })?.colors)

        //chart title
        svg.append("text")
            .style("font-size", textSize)
            .style("fill", 'rgba(0,0,0,0.65)')
            .style('text-anchor', 'middle')
            .style("dominant-baseline", "middle")
            .attr("x", textSize * 0.5)
            .attr("y", rootSize.height * 0.5)
            .attr('writing-mode', 'tb')
            .text(
                (
                    props.table_data?.fields?.find(
                        f => f.key === dimensionKey
                    )?.name || {}
                )[i18n.resolvedLanguage]
            );
        svg.append("text")
            .style("font-size", textSize)
            .style("fill", 'rgba(0,0,0,0.65)')
            .style('text-anchor', 'middle')
            .style("dominant-baseline", "middle")
            .attr("x", (xAxisSize.x1 + xAxisSize.x0 ) * 0.5)
            .attr("y", rootSize.height - textSize * 0.5)
            .text(
                countBy === "count_records" ? t('chart_view.count_records_label') : (
                    props.table_data?.fields?.find(
                        f => f.key === props.chart_view?.chart_config?.value
                    )?.name || {}
                )[i18n.resolvedLanguage]
            );

        // X Axis
        const xScale = d3
            .scaleLinear()
            .domain([0, xDomainMax])
            .range([xAxisSize.x0, xAxisSize.x1])
            .nice();
        svg
            .append('g')
            .style("font-size", textSize)
            .attr("transform", `translate(0, ${xAxisSize.y})`)
            .call(d3.axisBottom(xScale).ticks(xAxisSize.ticks))
        svg.append('g')
            .selectAll('allPolylines')
            .data(xScale.ticks(xAxisSize.ticks))
            .join('polyline')
            .attr("stroke", '#ddd')
            .style("fill", "none")
            .attr("stroke-width", 1)
            .attr("stroke-dasharray", '5,5')
            .attr('points', (d: any, i: number) => {
                return d > 0 ? [
                    [xScale(d), yAxisSize.y0],
                    [xScale(d), yAxisSize.y1]
                ] : []
            })
        if(!rootSize.showYAxis) {
            svg.append('polyline')
                .attr("stroke", 'black')
                .style("fill", "none")
                .attr("stroke-width", 1)
                .attr('points', [[xScale(0), yAxisSize.y0], [xScale(0), yAxisSize.y1]])
        }

        // Y Axis
        const yScale = d3
            .scaleBand()
            .domain(dimensionValues)
            .range([yAxisSize.y1, yAxisSize.y0])
            .padding(yAxisSize.padding)

        if(rootSize.showYAxis) {
            svg
                .append('g')
                .style("font-size", textSize)
                .attr("transform", `translate(${yAxisSize.x1}, 0)`)
                .call(d3.axisLeft(yScale))
                .selectAll(".tick text")
                .each(function (d: any) {
                    d3.select(this).append("title").text(d);
                    if (d.length > yAxisSize.ellipsis) {
                        d3.select(this).text(d.slice(0, yAxisSize.ellipsis) + '...');
                    }
                })
                .attr("transform", "rotate(-45)")
                .style("text-anchor", "end");
        }

        // legend
        if (stackedByKey && rootSize.showLegend) {
            svg.selectAll("legend")
                .data(stackedByValues)
                .enter()
                .append("circle")
                .attr("cx", legendSize.x + textSize * 0.5)
                .attr("cy", (d, i) => legendSize.y + i * textSize * 1.5)
                .attr("r", textSize * 0.5)
                .style("fill", (d, i) => colors(i))

            svg.selectAll("legend")
                .data(stackedByValues)
                .enter()
                .append("text")
                .style("dominant-baseline", "middle")
                .style("font-size", textSize)
                .attr("x", legendSize.x + textSize * 1.5)
                .attr("y", (d, i) => legendSize.y + i * textSize * 1.5 + textSize * 0.1 )
                .text((d, i) => d)
        }

        let data_ready = data.map(item => stackedByValues.filter(
            (stack, i) => item[stack] !== 0
        ));
        const MaxStackedCount = d3.max(data_ready, d => d.length);
        const RectHeight = yScale.bandwidth() * 1.0 / MaxStackedCount;

        // chart
        for (let xi = 0, len = data.length; xi <len; xi++) {
            if(data_ready[xi].length < 1) {
                continue;
            }
            const item = data[xi];
            const yOffset = (MaxStackedCount - data_ready[xi].length) / 2 * RectHeight;
            svg.append("g")
                .selectAll('rect')
                .data(data_ready[xi])
                .enter()
                .append('rect')
                .attr('x', (d, i) => xAxisSize.x0)
                .attr("y", (d, i) => yOffset + yScale(item[dimensionKey]) + i * RectHeight)
                .attr("width", (d, i) => xScale(item[d]) - xScale(0))
                .attr("height", RectHeight)
                .attr("fill", (d, i) => colors(stackedByValues.indexOf(d)))
                .on("mouseover",function (e, d) {
                    if(!!tooltipRef.current) {
                        tooltipRef.current.style.top = `${e.pageY + 10}px`;
                        tooltipRef.current.style.left = `${e.pageX + 10}px`;
                        tooltipRef.current.innerHTML = `${d || item[dimensionKey]}: ${formatData(item[d])}`;
                        tooltipRef.current.style.visibility = 'visible';
                        d3.select(e.toElement)
                            .transition()
                            .attr('opacity', 0.5)
                            .duration(200);
                    }
                }).on("mousemove",function (e, d) {
                    if(!!tooltipRef.current) {
                        tooltipRef.current.style.top = `${e.pageY + 10}px`;
                        tooltipRef.current.style.left = `${e.pageX + 10}px`;
                    }
                }).on("mouseout",function (e, d) {
                    if(!!tooltipRef.current) {
                        tooltipRef.current.style.visibility = 'hidden';
                        d3.select(e.fromElement)
                            .transition()
                            .attr('opacity', 1)
                            .duration(200);
                    }
                });
            if(RectHeight > textSize * 0.85) {
                svg.append("g")
                    .selectAll('text')
                    .data(data_ready[xi])
                    .enter()
                    .append("text")
                    .attr("x", (d, i) => xScale(item[d]) + textSize * 0.5)
                    .attr("y", (d, i) => yOffset + yScale(item[dimensionKey]) + i * RectHeight + RectHeight * 0.5)
                    .text((d, i) => formatData(item[d]))
                    .attr("alignment-baseline", "middle")
                    .style("fill", "black")
                    .style("font-size", textSize);
            }
        }
    }, [props, i18n, textSize, t])

    useEffect(() => {
        document.getElementById(`bar-chart-${props.id}`).innerHTML = "";
        if (
            (props.chart_view?.chart_config?.count_by === "count_a_field" && !props.chart_view?.chart_config?.value) ||
            !props.chart_view?.chart_config?.count_by ||
            !props.chart_view?.chart_config?.dimension ||
            !props.chart_view?.chart_config?.sort_by ||
            !props.chart_view?.chart_config?.sort_rule ||
            !props.table_data ||
            !props.table_data?.filtered_data) {
            return false;
        }
        try {
            drawChart();
        } catch(e) {
            return false;
        }
    }, [props, drawChart, windowSize])

    return (
        <>
            <div
                id={`bar-chart-${props.id}`}
                style={{ width: '100%', height: '100%', maxHeight: '' }}>
            </div>
            <div ref={tooltipRef} style={{
                position: 'fixed',
                background: 'white',
                border: '1px solid #f0f0f0',
                boxShadow: '1px 1px 7px 1px rgba(0,0,0,0.1)',
                padding: `${textSize / 2}px ${textSize}px`,
                visibility: 'hidden',
                zIndex: 99999,
                fontSize: textSize
            }}/>
        </>
    );
}

export default BarChart;

export const BarChartConfigForm = (props: {
    chart_view: ChartView;
    fields: any;
    form: any;
    is_superuser: boolean;
}) => {
    const { t, i18n } = useTranslation();

    return (
        <>
            <Form.Item
                label={t('chart_view.config.bar_chart.dimension')}
                name={['chart_config', 'dimension']}
                rules={[{ required: true, message: '' }]}>
                <Select
                    options={
                        props.fields?.map(item => ({
                            label: (item.name || {})[i18n.resolvedLanguage === 'zh' ? 'zh' : 'en'] || item.name,
                            value: item.key,
                        }))
                    }
                />
            </Form.Item>
            <Form.Item
                style={{ marginBottom: !props.form || props.form.getFieldValue("chart_config")?.count_by === "count_records" ? 24 : 0 }}
                label={t('chart_view.config.bar_chart.value')}
                name={['chart_config', 'count_by']}>
                <Radio.Group>
                    <Radio value={"count_records"}>{t("chart_view.count_records")}</Radio>
                    <Radio value={"count_a_field"}>{t("chart_view.count_a_field")}</Radio>
                </Radio.Group>
            </Form.Item>
            <div style={{ display: !props.form || props.form.getFieldValue("chart_config")?.count_by === "count_records" ? "none" : "flex" }}>
                <Form.Item
                    style={{ flex: 1 }}
                    name={['chart_config', 'value']}
                    rules={[{ required: props.form.getFieldValue("chart_config")?.count_by !== "count_records", message: t("chart_view.select_filed") }]}>
                    <Select
                        options={
                            props.fields?.filter(
                                item => item.type === "number" || item.type === "integer"
                            )?.map(item => ({
                                label: (item.name || {})[i18n.resolvedLanguage === 'zh' ? 'zh' : 'en'] || item.name,
                                value: item.key,
                            }))
                        }
                    />
                </Form.Item>
                <div style={{ width: 10 }}></div>
                <Form.Item
                    style={{ flex: 1 }}
                    initialValue="sum"
                    name={['chart_config', 'operation']}>
                    <Select
                        options={[
                            {
                                label: t('chart_view.sum'),
                                value: 'sum',
                            },
                            {
                                label: t('chart_view.min'),
                                value: 'min',
                            },
                            {
                                label: t('chart_view.max'),
                                value: 'max',
                            },
                            {
                                label: t('chart_view.average'),
                                value: 'average',
                            },
                        ]}
                    />
                </Form.Item>
            </div>
            <Form.Item
                label={t('chart_view.config.bar_chart.stacked_by')}
                name={['chart_config', 'stacked_by']}>
                <Select
                    options={
                        [{ label: t('chart_view.empty_stacked_by'), value: null }].concat(props.fields ? props.fields.map(item => ({
                            label: (item.name || {})[i18n.resolvedLanguage === 'zh' ? 'zh' : 'en'] || item.name,
                            value: item.key,
                        })) : [])
                    }
                />
            </Form.Item>
            <Collapse bordered={false} expandIconPosition="end" style={{ padding: 0, background: "white" }}>
                <Panel header={t("chart_view.more_settings")} key="more_settings" forceRender>
                    <Form.Item
                        label={t('chart_view.config.bar_chart.sort_by')}
                        name={['chart_config', 'sort_by']}>
                        <Radio.Group>
                            <Radio value={"x"}>{t("chart_view.sort_by_x")}</Radio>
                            <Radio value={"y"}>{t("chart_view.sort_by_y")}</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        label={t('chart_view.config.bar_chart.sort_rule')}
                        name={['chart_config', 'sort_rule']}>
                        <Radio.Group>
                            <Radio value={"ascending"}>{t("chart_view.sort_rule_ascending")}</Radio>
                            <Radio value={"descending"}>{t("chart_view.sort_rule_descending")}</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        label={t('chart_view.config.bar_chart.text_size')}
                        name={['chart_config', 'text_size']}>
                        <InputNumber style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item
                        label={t('chart_view.color_picker')}
                        name={['chart_config', 'color']}>
                        <Select
                            dropdownAlign={{
                                points: ['cl', 'cr']
                            }}
                            optionLabelProp="label"
                        >
                            <div style={{ pointerEvents: "none" }}>{t("chart_view.multi_color_theme")}</div>
                            {colorTheme.filter(item => item.value.startsWith("theme")).map(item => {
                                return <Option
                                    value={`${item.value}`}
                                    label={t(`chart_view.${item.value}`)}
                                >
                                    {item.colors.map(color => {
                                        return <div style={{ display: "inline-block", width: "10%", height: "30px", background: color }} />
                                    })}
                                </Option>
                            })}
                            <div style={{ pointerEvents: "none" }}>{t("chart_view.monochrome_gradient_theme")}</div>
                            {colorTheme.filter(item => !item.value.startsWith("theme")).map(item => {
                                return <Option
                                    value={`${item.value}`}
                                    label={t(`common.${item.value}`)}
                                >
                                    {item.colors.map(color => {
                                        return <div style={{ display: "inline-block", width: "10%", height: "30px", background: color }} />
                                    })}
                                </Option>
                            })}
                        </Select>
                    </Form.Item>
                    {props.is_superuser && <Form.Item
                        name='is_template'
                        valuePropName="checked">
                        <Checkbox>
                            {t('chart_view.is_template')}
                        </Checkbox>
                    </Form.Item>}
                </Panel>
            </Collapse>
        </>
    )
}