//app config
export const getDataList = (api: any, data: {
    list: string;
}, config: any) => {
    return api?.get(`${data.list}`, {...config})
}

export const getComparisonDataList = (api: any, config: any) => {
    return api?.get('comparison_df_info', {...config})
}

export const getDf = (api: any, data: {
    instance_id: string;
    df_name: string;
}, config: any) => {
    return api?.post(`df/get/${data.instance_id}`, {
        df_name: data.df_name
    }, {...config})
}

export const updateDf = (api: any, data: {
    instance_id: string;
    df_name: string;
    df_dict: any
}) => {
    return api?.post(`df/update/${data.instance_id}`, {
        df_name: data.df_name,
        df_dict: data.df_dict
    }, { headers: { needLoading: true } })
}

export const downloadData = (api: any, data: {
    instance_id: string;
    data_type: string;
    lang: any
}) => {
    return api?.post(`/data/${data.instance_id}`, {
        data_type: data.data_type,
        lang: data.lang
    }, { headers: { needLoading: true }, responseType: 'blob' })
}

export const getInstanceList = (api: any, data: {
    page?: number;
    page_size?: number;
}) => {
    return api?.get(`instance/list?page=${data.page}&page_size=${data.page_size}`)
}

