import {
  Button,
  Empty,
  Form,
  Pagination,
  Space,
  Table,
  Tooltip,
  DatePicker,
  Select,
  Modal,
  notification,
  Input,
  Checkbox,
} from "antd";
import { useEffect, useState } from "react";
import {
  getSystemAppListApi,
  getSystemChartViewsApi,
  updateSystemChartViewApi,
} from "../../api/ServerApi";
import { useTranslation } from "react-i18next";
import { ColumnsType } from "antd/es/table";
import viewUtils from "../../utils/viewUtils";
import NavHyperLink from "../../components/NavHyperLink";

const { RangePicker } = DatePicker;

const ChartList = () => {
  const { t } = useTranslation();
  const [criteria, setCriteria] = useState({});

  const handleSearch = (data: any) => {
    setCriteria(data);
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <NavHyperLink
          routes={[
            {
              path: "#",
              breadcrumbName: t("system_manage.nav.chart_list"),
            },
          ]}
        />
      </div>
      <Search onSubmit={handleSearch} />
      <ChartTable criteria={criteria} />
    </>
  );
};

const Search = (props: { onSubmit: (data: any) => void }) => {
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();
  const [appOptions, setAppOptions] = useState<any[]>();

  useEffect(() => {
    getSystemAppListApi({
      page: 1,
      page_size: 1,
      pagination: false,
    }).then((res) => {
      if (res.status) {
        setAppOptions([
          {
            value: null,
            label: t("common.all"),
          },
          ...(res.data || []).map((item) => {
            return { value: item.id, label: item.app_manifest?.display_name[i18n.resolvedLanguage] };
          }),
        ]);
      }
    });
  }, []);

  const handleSubmit = () => {
    props.onSubmit(form.getFieldsValue());
  };

  const handleReset = () => {
    form.resetFields();
    props.onSubmit(form.getFieldsValue());
  };

  return (
    <Form
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(3,1fr)",
        gridAutoRows: "auto",
        gap: "1em",
      }}
      form={form}
      colon={false}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 16 }}
      layout="inline"
      initialValues={{
        app_id: null,
      }}
    >
      <Form.Item name="app_id" label={t("system_manage.chart_list.app")}>
        <Select options={appOptions} />
      </Form.Item>
      <Form.Item name="created_at" label={t("system_manage.chart_list.created_at")}>
        <RangePicker />
      </Form.Item>
      <Form.Item
        style={{
          gridColumn: "1 / -1",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Space>
          <Button type="primary" htmlType="submit" onClick={handleSubmit}>
            {t("common.query")}
          </Button>
          <Button htmlType="reset" onClick={handleReset}>
            {t("common.reset")}
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

const ChartTable = (props: { criteria: any }) => {
  const { t, i18n } = useTranslation();
  const [datum, setDatum] = useState([]);
  const [total, setTotal] = useState();
  const [pagination, setPagination] = useState({ page: 1, page_size: 10 });

  useEffect(() => {
    getSystemChartViewList(pagination, props.criteria);
  }, [pagination]);

  useEffect(()=> {
    setPagination({page: 1, page_size: pagination.page_size});
  }, [props.criteria]);

  const handlePageChange = (page: number, page_size: number) => {
    setPagination((data) => ({ ...data, page: page, page_size: page_size }));
  };

  const handleSaved = () => {
    getSystemChartViewList(pagination, props.criteria);
  };

  const getSystemChartViewList = (pagination: any, criteria: any) => {
    let fromDate = null;
    let toDate = null;
    if (criteria.created_at && criteria.created_at.length > 0) {
      fromDate = new Date(Date.parse(criteria.created_at[0]));
      fromDate.setHours(0, 0, 0, 0);
    }
    if (criteria.created_at && criteria.created_at.length > 1) {
      toDate = new Date(Date.parse(criteria.created_at[1]));
      toDate.setHours(0, 0, 0, 0);
    }

    getSystemChartViewsApi({
      page: pagination.page,
      page_size: pagination.page_size,
      is_template: true,
      created_at_from: fromDate?.getTime(),
      created_at_to: toDate?.getTime(),
      ...criteria,
    }).then((res) => {
      if (res.status) {
        setDatum(res.data || []);
        setTotal(res?.pagination?.total || 0);
      } else {
        setDatum([]);
        setTotal(0);
      }
    });
  };

  const columns: ColumnsType<any> = [
    {
      title: t("system_manage.chart_list.chart_name"),
      key: "chart_name",
      render: (r) => (
        <Tooltip title={r?.name}>{r?.name}</Tooltip>
      ),
    },
    {
      title: t("system_manage.chart_list.app_name"),
      key: "app_name",
      render: (r) => (
        <Tooltip
          title={r?.app?.app_manifest?.display_name[i18n.resolvedLanguage]}
        >
          {r?.app?.app_manifest?.display_name[i18n.resolvedLanguage]}
        </Tooltip>
      ),
    },
    {
      title: t("system_manage.chart_list.created_by"),
      key: "owner",
      render: (r) => r.owner,
    },
    {
      title: t("system_manage.chart_list.created_at"),
      key: "created_at",
      render: (r) => viewUtils.utcToLocal(r.created_at),
    },
    {
      title: t("common.actions"),
      key: "actions",
      render: (r) => (
        <div className="btn-group mr-2">
          <EditButton chart={r} onSaved={handleSaved} />
        </div>
      ),
    },
  ];

  if (datum && datum.length > 0) {
    return (
      <div className="table-responsive bg-white" style={{ marginTop: "1em" }}>
        <Table
          pagination={false}
          rowKey={(r) => r.id}
          columns={columns}
          dataSource={datum}
          scroll={{ x: 700, scrollToFirstRowOnChange: true }}
        />
        <Pagination
          showQuickJumper
          showTotal={(total) => t("common.total", { count: total })}
          current={pagination.page}
          pageSize={pagination.page_size}
          total={total}
          onChange={handlePageChange}
          showSizeChanger
          pageSizeOptions={[10, 20, 50]}
          style={{ textAlign: "right", justifyContent: 'flex-end', margin: "1em" }}
        />
      </div>
    );
  } else {
    return (
      <div className="bg-white" style={{ padding: "10%" }}>
        <Empty/>
      </div>
    );
  }
};

const EditButton = (props: { chart?: any; onSaved: () => void }) => {
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if(!open) {
      return;
    }

    form.setFieldsValue({
      chart_name: props.chart?.name,
      app_name: props.chart?.app?.app_manifest?.display_name[i18n.resolvedLanguage],
      is_template: props.chart?.template_config?.is_template,
    })
  }, [open])

  const handleOk = async () => {
    try {
      const formData = await form.validateFields();
      const response = await updateSystemChartViewApi({
        id: props.chart.id,
        template_config: {...props.chart.template_config, is_template: formData.is_template},
      });
      if (response.status) {
        notification.success({
          message: t(
            props.chart
              ? "system_manage.chart_list.tip.save"
              : "system_manage.chart_list.tip.create",
            { status: t("common.success") }
          ),
        });
        setOpen(false);
        props.onSaved();
      } else {
        notification.error({
          message: t(
            props.chart
              ? "system_manage.chart_list.tip.save"
              : "system_manage.chart_list.tip.create",
            { status: t("common.fail") }
          ),
        });
      }
    } catch (e) {
      return;
    }
  };

  return (
    <>
      <div className="d-flex justify-content-end align-items-center mb-3">
        <Button type="primary" onClick={() => setOpen(true)}>
          {props.chart ? t("common.edit") : t("common.new")}
        </Button>
      </div>
      <Modal
        title={
          props.chart
            ? `${t("common.edit")}${t("system_manage.chart_list.title")}`
            : `${t("common.new")}${t("system_manage.chart_list.title")}`
        }
        okText={props.chart ? t("common.save") : t("common.new")}
        cancelText={t("common.cancel")}
        open={open}
        onOk={handleOk}
        onCancel={() => setOpen(false)}
      >
        <Form
          form={form}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 16 }}
          autoComplete="off"
        >
          <Form.Item
            label={t("system_manage.chart_list.chart_name")}
            name="chart_name"
          >
            <Input disabled/>
          </Form.Item>
          <Form.Item
            label={t("system_manage.chart_list.app_name")}
            name="app_name"
          >
            <Input disabled/>
          </Form.Item>
          <Form.Item
            label={t("system_manage.chart_list.set_as_template")}
            name="is_template"
            valuePropName="checked"
          >
            <Checkbox/>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ChartList;
