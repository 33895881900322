import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import {
  Modal,
  Form,
  Input,
  Button,
  message
} from 'antd'

import { createWorkspace, closeCreateWorkspaceModal, setDefaultWorkspace } from '../store/account'

import { getConfig } from '../config/config';
const config = getConfig();

const CreateWorkspaceModal = (props:any) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()

  const ForceCreateWorkspace = props.workspaces.length < 1

  useEffect(() => {
    form.resetFields();
  }, [props.show_create_workspace_modal, ForceCreateWorkspace])

  const handleClose = () => {
    if(!ForceCreateWorkspace) {
      props.closeCreateWorkspaceModal()
    }
  }

  const handleCreateInstance = (data) => {
    props.createWorkspace({
      name: data.name,
      success: (res) => {
        message.success( t('workspace.tips.create', {status: t('common.success')}) )
        if(props.show_create_workspace_modal){
          props.closeCreateWorkspaceModal()
        }
        props.setDefaultWorkspace({
          id: res.data.id,
          success: () => {
            window.location.href = '/'
          },
          fail: () => {
            message.error( t('workspace.tips.set_default', {status: t('common.fail')}) )
          }
        })
      },
      fail: (res) => {
        message.error( t('workspace.tips.create', {status: t('common.fail')}) )
      }
    })
  }

  return(
    <Modal
      centered
      forceRender
      maskClosable={!ForceCreateWorkspace}
      open={props.show_create_workspace_modal || ForceCreateWorkspace}
      onCancel={handleClose}
      footer={null}
    >
      <Form
        form={form}
        name="create workspace"
        onFinish={handleCreateInstance}
        className='text-center px-5 pt-5 pb-4'
      >
        <div className='mb-2' style={{fontSize: '1.2em', fontWeight: 'bold'}}>
          {t(config.isSupplyChainAIPlatform ? 'supply_chain_ai_platform.app_title' : 'app_title')}
        </div>
        <div className='mb-5'>
          <span className='primary' style={{lineHeight: '3em'}}>
            {t('workspace.create_title')}
          </span>
        </div>
        <Form.Item className='mb-5' name="name" rules={[{ required: true, max:20, message: '' }]}>
          <Input placeholder={t('workspace.name')} showCount maxLength={20} />
        </Form.Item>
        <Button type="primary" htmlType="submit" style={{width: '60%'}}>
          {t('common.create')}
        </Button>
      </Form>
    </Modal>
  )
}

const mapStateToProps = (store) => ({
  workspaces: store.account.workspaces,
  show_create_workspace_modal: store.account.show_create_workspace_modal
})

const mapDispatchToProps = (dispatch) => bindActionCreators({
  createWorkspace,
  closeCreateWorkspaceModal,
  setDefaultWorkspace,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(CreateWorkspaceModal)
