import React, { useEffect, useRef, useState } from "react";
import { Map, ZoomControl } from 'react-bmapgl';
import * as mapvgl from 'mapvgl';
import BMapLoader from '../../BMapLoader';
import { useTranslation } from 'react-i18next';
import { Form, Select, Slider, Switch, InputNumber, Collapse, Checkbox } from 'antd';
import { ChartView } from '../../interfaces/chartView';
const { Panel } = Collapse;

type Props = {
    id: string;
    chart_view: any;
    table_data: any;
};

const BaiduLocationRelationship = (props: Props) => {
    const [mapSdkLoading, setMapSdkLoading] = useState(false);
    const mapRef: any = useRef();
    const tooltipRef = useRef();
    const mainTooltipRef = useRef();
    const textSize = 12;

    useEffect(() => {
        let view = null;
        let subPointlayer = null;
        let mainPointlayer = null;
        let lineLayer = null;
        if(
            !props.chart_view?.chart_config?.main_latitude ||
            !props.chart_view?.chart_config?.main_longitude ||
            !props.chart_view?.chart_config?.sub_latitude ||
            !props.chart_view?.chart_config?.sub_longitude ||
            !props.chart_view?.chart_config?.app_key ||
            !props.table_data ||
            !props.table_data?.filtered_data) {
            return false;
        }
        (async() => {
            BMapLoader({
              key: props.chart_view?.chart_config?.app_key,
            }).then(() => {
                setMapSdkLoading(true);
                if(!mapRef.current) {
                    return;
                }
                let lineData = [];
                let mainScatterData = {};
                let subScatterData = {};
                for(let i = 0, len = props.table_data?.filtered_data?.length || 0; i < len; i++) {
                    let rowData = (props.table_data?.filtered_data || [])[i];
                    const main_longitude = rowData[props.chart_view?.chart_config?.main_longitude];
                    const main_latitude = rowData[props.chart_view?.chart_config?.main_latitude];
                    const sub_longitude = rowData[props.chart_view?.chart_config?.sub_longitude];
                    const sub_latitude = rowData[props.chart_view?.chart_config?.sub_latitude];
                    if(!!main_longitude && !!main_latitude && !!sub_longitude && !!sub_latitude) {
                        lineData.push({
                            geometry: {
                                type: "LineString",
                                coordinates: [
                                    [ sub_longitude, sub_latitude ],
                                    [ main_longitude, main_latitude ],
                                ]
                            }
                        })
                    }
                    if(!!main_longitude && !!main_latitude) {
                        const main_lnglat = `${main_longitude},${main_latitude}`;
                        if(!mainScatterData[main_lnglat]) {
                            mainScatterData[main_lnglat] = {
                                geometry: {
                                    type: "Point",
                                    coordinates: [ main_longitude, main_latitude ],
                                },
                                properties: {
                                    name: rowData[
                                        props.chart_view?.chart_config?.main_name
                                    ],
                                }
                            }
                        }
                    }
                    if(!!sub_longitude && !!sub_latitude) {
                        const sub_lnglat = `${sub_longitude},${sub_latitude}`;
                        if(!subScatterData[sub_lnglat]) {
                            subScatterData[sub_lnglat] = {
                                geometry: {
                                    type: "Point",
                                    coordinates: [ sub_longitude, sub_latitude]
                                },
                                properties: {
                                    name: rowData[
                                        props.chart_view?.chart_config?.sub_name
                                    ],
                                }
                            }
                        }
                    }
                }
                view = new mapvgl.View({
                    map: mapRef.current.map
                });
                lineLayer = new mapvgl.LineLayer({
                    color: 'rgba(100,100,100,0.7)',
                    width: props.chart_view?.chart_config?.line_width || 1,
                    data: lineData
                });
                subPointlayer = new mapvgl.PointLayer({
                    color: 'rgba(100,94,249,1)',
                    shape: 'circle',
                    size: props.chart_view?.chart_config?.sub_size || 8,
                    data: Object.values(subScatterData),
                    enablePicked: props.chart_view?.chart_config?.show_tip && props.chart_view?.chart_config?.sub_name,
                    onMousemove: (e) => {
                        if(props.chart_view?.chart_config?.show_tip && props.chart_view?.chart_config?.sub_name) {
                            if(!!e.dataItem) {
                                tooltipRef.current.innerHTML = `${e.dataItem?.properties?.name}`;
                                tooltipRef.current.style.visibility = 'visible';
                            } else {
                                tooltipRef.current.style.visibility = 'hidden';
                            }
                        }
                    },
                });
                mainPointlayer = new mapvgl.PointLayer({
                    color: 'rgba(255,0,0,1)',
                    shape: 'circle',
                    size: props.chart_view?.chart_config?.main_size || 15,
                    data: Object.values(mainScatterData),
                    enablePicked: props.chart_view?.chart_config?.show_tip && props.chart_view?.chart_config?.main_name,
                    onMousemove: (e) => {
                        if(props.chart_view?.chart_config?.show_tip && props.chart_view?.chart_config?.main_name) {
                            if(!!e.dataItem) {
                                mainTooltipRef.current.innerHTML = `${e.dataItem?.properties?.name}`;
                                mainTooltipRef.current.style.visibility = 'visible';
                            } else {
                                mainTooltipRef.current.style.visibility = 'hidden';
                            }
                        }
                    },
                });
                view.addLayer(lineLayer);
                view.addLayer(subPointlayer);
                view.addLayer(mainPointlayer);
            }).catch(e => {
                setMapSdkLoading(false);
            });
        })();
        return () => {
            if(!!view) {
                if(!!lineLayer) {
                    view.removeLayer(lineLayer);
                    lineLayer.destroy();
                }
                if(!!subPointlayer) {
                    view.removeLayer(subPointlayer);
                    subPointlayer.destroy();
                }
                if(!!mainPointlayer) {
                    view.removeLayer(mainPointlayer);
                    mainPointlayer.destroy();
                }
                view.destroy();
            }
        };
    }, [props])

    return (
        <div style={{width: '100%', height: '100%', position: "relative"}}>
            <div
                id={`baidu-location-relationship-${props.id}`}
                className='baidu-map'
                style={{ width: '100%', height: '100%' }}>
                {
                    mapSdkLoading && <Map
                        ref={mapRef}
                        style={{ width: '100%', height: '100%' }}
                        center={!!BMapGL && new BMapGL.Point(108.552500, 34.322700)}
                        zoom={5}
                    >
                        <ZoomControl />
                    </Map>
                }
            </div>
            <div ref={tooltipRef} style={{
                position: 'absolute',
                background: 'white',
                border: '1px solid #f0f0f0',
                boxShadow: '1px 1px 7px 1px rgba(0,0,0,0.1)',
                padding: `${textSize / 2}px ${textSize}px`,
                top: 0,
                right: 0,
                visibility: 'hidden',
                zIndex: 999,
                fontSize: textSize
            }}/>
            <div ref={mainTooltipRef} style={{
                position: 'absolute',
                background: 'white',
                border: '1px solid #f0f0f0',
                boxShadow: '1px 1px 7px 1px rgba(0,0,0,0.1)',
                padding: `${textSize / 2}px ${textSize}px`,
                top: 0,
                right: 0,
                visibility: 'hidden',
                zIndex: 999,
                fontSize: textSize
            }}/>
        </div>
    );
}

export default BaiduLocationRelationship;


export const BaiduLocationRelationshipConfigForm = (props: {
    chart_view: ChartView;
    fields: any;
    form: any;
    is_superuser: boolean;
}) => {
    const { t, i18n } = useTranslation();

    return (
        <>
            <Form.Item
                label={t('chart_view.config.location_relationship.main_longitude')}
                name={['chart_config', 'main_longitude']}
                rules={[{ required: true, message: '' }]}>
                <Select
                    options={
                        props.fields?.filter(
                            item => item.type === "number"
                        )?.map(item => ({
                            label: (item.name || {})[i18n.resolvedLanguage === 'zh' ? 'zh' : 'en'] || item.name,
                            value: item.key,
                        }))
                    }
                />
            </Form.Item>
            <Form.Item
                label={t('chart_view.config.location_relationship.main_latitude')}
                name={['chart_config', 'main_latitude']}
                rules={[{ required: true, message: '' }]}>
                <Select
                    options={
                        props.fields?.filter(
                            item => item.type === "number"
                        )?.map(item => ({
                            label: (item.name || {})[i18n.resolvedLanguage === 'zh' ? 'zh' : 'en'] || item.name,
                            value: item.key,
                        }))
                    }
                />
            </Form.Item>
            <Form.Item
                label={t('chart_view.config.location_relationship.sub_longitude')}
                name={['chart_config', 'sub_longitude']}
                rules={[{ required: true, message: '' }]}>
                <Select
                    options={
                        props.fields?.filter(
                            item => item.type === "number"
                        )?.map(item => ({
                            label: (item.name || {})[i18n.resolvedLanguage === 'zh' ? 'zh' : 'en'] || item.name,
                            value: item.key,
                        }))
                    }
                />
            </Form.Item>
            <Form.Item
                label={t('chart_view.config.location_relationship.sub_latitude')}
                name={['chart_config', 'sub_latitude']}
                rules={[{ required: true, message: '' }]}>
                <Select
                    options={
                        props.fields?.filter(
                            item => item.type === "number"
                        )?.map(item => ({
                            label: (item.name || {})[i18n.resolvedLanguage === 'zh' ? 'zh' : 'en'] || item.name,
                            value: item.key,
                        }))
                    }
                />
            </Form.Item>
            <Form.Item
                noStyle
                shouldUpdate={(prevValues, curValues) =>
                    prevValues?.show_tip !== curValues?.show_tip
                }>
                {() => {
                    if (!props.form || !props.form.getFieldValue("chart_config")?.show_tip) {
                        return <></>;
                    }
                    return (
                        <>
                            <Form.Item
                                label={t('chart_view.config.location_relationship.main_name')}
                                name={['chart_config', 'main_name']}
                                rules={[{ required: true, message: '' }]}>
                                <Select
                                    options={
                                        props.fields?.filter(
                                            item => item.type === "string"
                                        )?.map(item => ({
                                            label: (item.name || {})[i18n.resolvedLanguage === 'zh' ? 'zh' : 'en'] || item.name,
                                            value: item.key,
                                        }))
                                    }
                                />
                            </Form.Item>
                            <Form.Item
                                label={t('chart_view.config.location_relationship.sub_name')}
                                name={['chart_config', 'sub_name']}
                                rules={[{ required: true, message: '' }]}>
                                <Select
                                    options={
                                        props.fields?.filter(
                                            item => item.type === "string"
                                        )?.map(item => ({
                                            label: (item.name || {})[i18n.resolvedLanguage === 'zh' ? 'zh' : 'en'] || item.name,
                                            value: item.key,
                                        }))
                                    }
                                />
                            </Form.Item>
                        </>
                    );
                }}
            </Form.Item>
            <Collapse bordered={false} expandIconPosition="end" style={{padding: 0, background: "white"}}>
                <Panel header={t("chart_view.more_settings")} key="more_settings" forceRender>
                    <Form.Item
                    label={t('chart_view.config.location_relationship.opacity')}
                    name={['chart_config', 'opacity']}>
                        <Slider
                        min={0}
                        max={1}
                        step={0.1}
                        />
                    </Form.Item>
                    <Form.Item
                        label={t('chart_view.config.location_relationship.show_tip')}
                        name={['chart_config', 'show_tip']}
                        valuePropName="checked">
                        <Switch />
                    </Form.Item>
                    <Form.Item
                        label={t('chart_view.config.location_relationship.main_size')}
                        name={['chart_config', 'main_size']}>
                        <InputNumber style={{ width: '100%'}} />
                    </Form.Item>
                    <Form.Item
                        label={t('chart_view.config.location_relationship.sub_size')}
                        name={['chart_config', 'sub_size']}>
                        <InputNumber style={{ width: '100%'}} />
                    </Form.Item>
                    <Form.Item
                        label={t('chart_view.config.location_relationship.line_width')}
                        name={['chart_config', 'line_width']}>
                        <InputNumber style={{ width: '100%'}} />
                    </Form.Item>
                    {props.is_superuser && <Form.Item
                        name='is_template'
                        valuePropName="checked">
                        <Checkbox>
                            {t('chart_view.is_template')}
                        </Checkbox>
                    </Form.Item>}
                </Panel>
            </Collapse>
        </>
    )
}