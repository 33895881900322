import { RunInstanceMember } from '../interfaces/runInstanceMember';
import { responseData, SERVER_API } from './BaseApi';

//planning sessions
export const getSessionsApi = (data: {
    app_id: string;
    workspace_id: string;
    page: number;
    page_size: number;
    search_key_words?: string;
    active?: boolean;
    order_by_created_at?: string;
    order_by_updated_at?: string;
    order_by_name?: string;
    order_by?: string;
}) => {
    return SERVER_API.post<responseData>(`sessions/list?page=${data.page}&page_size=${data.page_size}`, {
        app_id: data.app_id,
        workspace_id: data.workspace_id,
        ...( data.active !== undefined ? { active: data.active } : null ),
        ...( data.search_key_words ? { search_key_words: data.search_key_words } : null ),
        ...( data.order_by ? { order_by: data.order_by } : null ),
        ...( data.order_by_name ? { order_by_name: data.order_by_name } : null ),
        ...( data.order_by_updated_at ? { order_by_updated_at: data.order_by_updated_at } : null ),
        ...( data.order_by_created_at ? { order_by_created_at: data.order_by_created_at } : null ),
    }, {})
}

export const getSessionApi = (data: {
    session_id: string;
}) => {
    return SERVER_API.get<responseData>(`sessions/get/${data.session_id}`)
}

export const createSessionApi = (data: {
    app_id: string;
    workspace_id: string;
    name: string;
    description: string;
}) => {
    return SERVER_API.post<responseData>(`sessions/create`, {
        app_id: data.app_id,
        workspace_id: data.workspace_id,
        name: data.name,
        description: data.description,
    }, { headers: { needLoading: true } })
}

export const updateSessionApi = (data: {
    session_id: string;
    active?: boolean;
    name?: string;
    description?: string;
    is_deleted?: boolean;
    needLoading?: boolean;
}) => {
    return SERVER_API.post<responseData>(`sessions/update/${data.session_id}`, {
        ...( data.active !== null ? { active: data.active } : null ),
        ...( data.name ? { name: data.name } : null ),
        ...( data.description ? { description: data.description } : null ),
        ...( data.is_deleted ? { is_deleted: data.is_deleted } : null ),
    }, { headers: { needLoading: data.needLoading !== undefined? data.needLoading: true } })
}

//run instances
export const getInstancesApi = (data: {
    app_id?: string;
    workspace_id: string;
    page: number;
    page_size: number;
    session_id?: string;
    search_key_words?: string;
    active: boolean;
    order_by_created_at?: string;
    order_by_updated_at?: string;
    order_by_session_name?: string;
    order_by?: string;
}) => {
    return SERVER_API.post<responseData>(`run_instances/list?page=${data.page}&page_size=${data.page_size}`, {
        ...( data.app_id ? { app_id: data.app_id } : null ),
        workspace_id: data.workspace_id,
        active: data.active,
        ...( data.session_id ? { session_id: data.session_id } : null ),
        ...( data.search_key_words ? { search_key_words: data.search_key_words } : null ),
        ...( data.order_by ? { order_by: data.order_by } : null ),
        ...( data.order_by_session_name ? { order_by_session_name: data.order_by_session_name } : null ),
        ...( data.order_by_updated_at ? { order_by_updated_at: data.order_by_updated_at } : null ),
        ...( data.order_by_created_at ? { order_by_created_at: data.order_by_created_at } : null ),
    }, {})
}

export const getInstanceApi = (data: {
    instance_id: string;
}) => {
    return SERVER_API.get<responseData>(`run_instances/get/${data.instance_id}`)
}

export const updateInstanceApi = (data: {
    instance_id: string;
    active?: boolean;
    name?: string;
    description?: string;
    custom_fields?: any;
    is_locked?: boolean;
    is_deleted?: boolean;
    needLoading?: boolean;
}) => {
    return SERVER_API.post<responseData>(`run_instances/update/${data.instance_id}`, {
        ...( data.active !== null ? { active: data.active } : null ),
        ...( data.name ? { name: data.name } : null ),
        ...( data.description ? { description: data.description } : null ),
        ...( data.custom_fields ? { custom_fields: data.custom_fields } : null ),
        ...( data.is_locked !== null ? { is_locked: data.is_locked } : null ),
        ...( data.is_deleted !== null ? { is_deleted: data.is_deleted } : null ),
    }, { headers: { needLoading: data.needLoading !== undefined? data.needLoading: true } })
}

export const getInstanceMembersApi = (data: {
    instance_id: string;
}) => {
    return SERVER_API.get<responseData>(`run_instances/get/${data.instance_id}/members`)
}

export const updateInstanceMembersApi = (data: {
    instance_id: string;
    members: RunInstanceMember[];
    locale: string;
    app_id: string;
}) => {
    return SERVER_API.post<responseData>(`run_instances/update/${data.instance_id}/members`, {
        members: data.members,
        locale: data.locale,
        app_id: data.app_id,
    }, { headers: { needLoading: true } })
}

//tasks
export const getTasksApi = (data: {
    app_id: string;
    workspace_id: string;
    page: number;
    page_size: number;
    instance_id: string;
    order_by_created_at?: string;
    search_key_words?: string;
    order_by_created_at?: string;
    order_by_process_type?: string;
    order_by_run_instance_name?: string;
    order_by?: string;
}) => {
    return SERVER_API.post<responseData>(`tasks/list?page=${data.page}&page_size=${data.page_size}`, {
        app_id: data.app_id,
        workspace_id: data.workspace_id,
        order_by_created_at: data.order_by_created_at || 'desc',
        run_instance_id: data.instance_id,
        ...( data.search_key_words ? { search_key_words: data.search_key_words } : null ),
        ...( data.order_by ? { order_by: data.order_by } : null ),
        ...( data.order_by_process_type ? { order_by_process_type: data.order_by_process_type } : null ),
        ...( data.order_by_run_instance_name ? { order_by_run_instance_name: data.order_by_run_instance_name } : null ),
        ...( data.order_by_created_at ? { order_by_created_at: data.order_by_created_at } : null ),
    }, {})
}

export const getTaskLogsApi = (data: {
    process_id: string;
}) => {
    return SERVER_API.post<responseData>(`tasks/logs`, {
        process_id: data.process_id
    }, {})
}

export const solveTaskApi = (data: {
    session_id: string;
    instance_id: string;
    pipeline_name: string;
    pipeline_config: any;
    extra_config: any;
}) => {
    return SERVER_API.post<responseData>(`tasks/solve`, {
        session_id: data.session_id,
        run_instance_id: data.instance_id,
        pipeline_name: data.pipeline_name,
        pipeline_config: data.pipeline_config,
        extra_config: data.extra_config,
    }, { headers: { needLoading: true } })
}

export const importTaskApi = (data: {
    session_id: string;
    name: string;
    description: string;
    custom_fields: any;
    pipeline_name: string;
    pipeline_config: any;
}) => {
    return SERVER_API.post<responseData>(`tasks/import`, {
        session_id: data.session_id,
        name: data.name,
        description: data.description,
        custom_fields: data.custom_fields,
        pipeline_name: data.pipeline_name,
        pipeline_config: data.pipeline_config,
    }, { headers: { needLoading: true } })
}

export const cloneTaskApi = (data: {
    session_id: string;
    instance_id: string;
    name: string;
    description: string;
    custom_fields: any;
    pipeline_name: string;
    pipeline_config: any;
    active: boolean;
}) => {
    return SERVER_API.post<responseData>(`tasks/clone`, {
        session_id: data.session_id,
        source_run_instance_id: data.instance_id,
        name: data.name,
        description: data.description,
        custom_fields: data.custom_fields,
        pipeline_name: data.pipeline_name,
        pipeline_config: data.pipeline_config,
        active: data.active,
    }, { headers: { needLoading: true } })
}

export const generateUrlTaskApi = (data: {
    file_name: string;
    method: string;
    description: string;
}) => {
    return SERVER_API.post<responseData>(`tasks/generate_url`, {
        file_name: data.file_name,
        file_type: data.file_type,
        method: data.method,
    }, {})
}

export const updateTaskApi = (data: {
    task_id: string;
    workspace_id: string;
    remark?: string;
}) => {
    return SERVER_API.post<responseData>(`tasks/update/${data.task_id}`, {
        workspace_id: data.workspace_id,
        ...( data.remark !== null ? { remark: data.remark } : null ),
    }, { headers: { needLoading: true } })
}

export const terminateTaskApi = (data: {
    process_id: string;
}) => {
    return SERVER_API.post<responseData>(`tasks/terminate`, {
        process_id: data.process_id
    }, {})
}

//dashboard
export const getRunStatsApi = (data: {
    app_id: string;
    workspace_id: string;
}) => {
    return SERVER_API.post<responseData>(`dashboard/run_stats`, {
        app_id: data.app_id,
        workspace_id: data.workspace_id,
    }, {})
}

export const getSystemAppListApi = (data: {
    page: number;
    page_size: number;
    pagination: boolean;
}) => {
    return SERVER_API.get<responseData>(`system/app_list?page=${data.page}&page_size=${data.page_size}&pagination=${data.pagination}`)
}

export const getSystemAppPermissionListApi = (data: {
    page: number,
    page_size: number,
    workspace_id?: string,
    created_at_from?: string,
    created_at_to?: string,
}) => {
    const queryParams = new URLSearchParams();

    if (data.page) queryParams.append("page", data.page.toString());
    if (data.page_size) queryParams.append("page_size", data.page_size.toString());
    if (data.workspace_id) queryParams.append("workspace_id", data.workspace_id);
    if (data.created_at_from) queryParams.append("created_at_from", data.created_at_from);
    if (data.created_at_to) queryParams.append("created_at_to", data.created_at_to);

    return SERVER_API.get<responseData>(`system/app_permission_list?${queryParams.toString()}`)
}

export const saveSystemAppPermissionApi = (data: {
    id?: string,
    app_id?: string,
    workspace_id?: string,
    company?: string,
    from_time?: string,
    to_time?: string,
    delete_flag?: boolean,
}[]) => {
    return SERVER_API.post<responseData>(`system/save_app_permission`, data)
}

export const getSystemUserListApi = (data: {
    page: number,
    page_size: number,
    account?: string,
    is_superuser?: boolean,
    active?: boolean,
    workspace_id?: string,
    created_at_from?: string,
    created_at_to?: string,
}) => {
    const queryParams = new URLSearchParams();

    if (data.page) queryParams.append("page", data.page.toString());
    if (data.page_size) queryParams.append("page_size", data.page_size.toString());
    if (data.account) queryParams.append("account", data.account);
    if (typeof data.is_superuser === "boolean") queryParams.append("is_superuser", `${data.is_superuser}`);
    if (typeof data.active === "boolean") queryParams.append("active", `${data.active}`);
    if (data.workspace_id) queryParams.append("workspace_id", data.workspace_id);
    if (data.created_at_from) queryParams.append("created_at_from", data.created_at_from);
    if (data.created_at_to) queryParams.append("created_at_to", data.created_at_to);

    return SERVER_API.get<responseData>(`system/user_list?${queryParams.toString()}`)
}

export const getSystemTaskDataApi = (data: {
    workspace_id?: string,
    account?: string,
    process_type?: string,
    created_at_from?: string,
    created_at_to?: string,
}) => {
    return SERVER_API.post<responseData>(`system/task_data`, {
        ...( data.workspace_id !== null ? { workspace_id: data.workspace_id } : null ),
        ...( data.account !== null ? { account: data.account } : null ),
        ...( data.process_type !== null ? { process_type: data.process_type } : null ),
        ...( data.created_at_from !== null ? { created_at_from: data.created_at_from } : null ),
        ...( data.created_at_to !== null ? { created_at_to: data.created_at_to } : null ),
    })
}

export const getSystemSessionDataApi = (data: {
    workspace_id?: string,
    account?: string,
    created_at_from?: string,
    created_at_to?: string,
}) => {
    return SERVER_API.post<responseData>(`system/session_data`, {
        ...( data.workspace_id !== null ? { workspace_id: data.workspace_id } : null ),
        ...( data.account !== null ? { account: data.account } : null ),
        ...( data.created_at_from !== null ? { created_at_from: data.created_at_from } : null ),
        ...( data.created_at_to !== null ? { created_at_to: data.created_at_to } : null ),
    })
}

export const getSystemInstanceDataApi = (data: {
    workspace_id?: string,
    account?: string,
    created_at_from?: string,
    created_at_to?: string,
}) => {
    return SERVER_API.post<responseData>(`system/instance_data`, {
        ...( data.workspace_id !== null ? { workspace_id: data.workspace_id } : null ),
        ...( data.account !== null ? { account: data.account } : null ),
        ...( data.created_at_from !== null ? { created_at_from: data.created_at_from } : null ),
        ...( data.created_at_to !== null ? { created_at_to: data.created_at_to } : null ),
    })
}

export const getSystemChartViewsApi = (data: {
    page: number,
    page_size: number,
    app_id?: string,
    workspace_id?: string,
    session_id?: string,
    run_instance_id?: string,
    is_template?: boolean,
    created_at_from?: string,
    created_at_to?: string,
}) => {
    const queryParams = new URLSearchParams();

    if (data.page) queryParams.append("page", data.page.toString());
    if (data.page_size) queryParams.append("page_size", data.page_size.toString());
    if (data.app_id) queryParams.append("app_id", data.app_id);
    if (data.workspace_id) queryParams.append("workspace_id", data.workspace_id);
    if (data.session_id) queryParams.append("session_id", data.session_id);
    if (data.run_instance_id) queryParams.append("run_instance_id", data.run_instance_id);
    if (typeof data.is_template === "boolean") queryParams.append("is_template", `${data.is_template}`);
    if (data.created_at_from) queryParams.append("created_at_from", data.created_at_from);
    if (data.created_at_to) queryParams.append("created_at_to", data.created_at_to);

    return SERVER_API.get<responseData>(`system/chart_list?${queryParams.toString()}`)
}

export const updateSystemChartViewApi = (data: {
    id: string;
    name?: string;
    filters?: any;
    dataframe?: string;
    chart_type?: string;
    chart_config?: any;
    is_deleted?: boolean;
    template_config?: boolean;
}) => {
    return SERVER_API.post<responseData>(`system/update_chart/${data.id}`, {
        ...( data.name ? { name: data.name } : null ),
        ...( data.filters ? { filters: data.filters } : null ),
        ...( data.dataframe ? { dataframe: data.dataframe } : null ),
        ...( data.is_deleted !== null ? { is_deleted: data.is_deleted } : null ),
        ...( data.chart_type ? { chart_type: data.chart_type } : null ),
        ...( data.chart_config ? { chart_config: data.chart_config } : null ),
        ...( data.template_config ? { template_config: data.template_config } : null ),
    }, { headers: { needLoading: true } })
}

//admin all user manage
export const getUsersApi = (data: {
    page: number;
    page_size: number;
}) => {
    return SERVER_API.get<responseData>(`users/list?page=${data.page}&page_size=${data.page_size}`)
}

export const updateUserApi = (data: {
    user_id: string;
    active?: boolean;
    is_superuser: boolean;
}) => {
    return SERVER_API.put<responseData>(`users/update/${data.user_id}`, {
        ...( data.active !== null ? { active: data.active } : null ),
        ...( data.is_superuser !== null ? { is_superuser: data.is_superuser } : null ),
    }, { headers: { needLoading: true } })
}

export const refreshSuperuserAuthTokenApi = () => {
    return SERVER_API.post<responseData>(`users/refresh_token`, {}, { headers: { needLoading: true } })
}

//admin all apps manage
export const registerAppApi = (data: {
    app_manifest: any;
    app_type: string;
    private_workspace_ids: List<string>;
}) => {
    return SERVER_API.post<responseData>(`apps/register`, {
        app_manifest: data.app_manifest,
        app_type: data.app_type,
        private_workspace_ids: data.private_workspace_ids,
    }, { headers: { needLoading: true } })
}

export const updateAppApi = (data: {
    app_id: string;
    active?: boolean;
    app_manifest?: any;
    app_type?: string;
    private_workspace_ids?: List<string>;
}) => {
    return SERVER_API.put<responseData>(`apps/update/${data.app_id}`, {
        ...( data.active !== null ? { active: data.active } : null ),
        ...( data.app_manifest ? { app_manifest: data.app_manifest } : null ),
        ...( data.app_type ? { app_type: data.app_type } : null ),
        ...( data.private_workspace_ids ? { private_workspace_ids: data.private_workspace_ids } : null ),
    }, { headers: { needLoading: true } })
}

export const getAppsApi = (data: {
    page: number;
    page_size: number;
}) => {
    return SERVER_API.get<responseData>(`apps/list?page=${data.page}&page_size=${data.page_size}`)
}

//me
export const getMeApi = () => {
    return SERVER_API.get<responseData>(`me/`)
}

export const getHealthApi = () => {
    return SERVER_API.get<responseData>(`me/health`)
}

export const changeDefaultWorkspaceApi = (data: {
    id: string;
}) => {
    return SERVER_API.put<responseData>(`me/set_default_workspace/${data.id}`, {}, { headers: { needLoading: true } })
}

//workspace
export const updateWorkspaceApi = (data: {
    workspace_id: string;
    name: string;
}) => {
    return SERVER_API.put<responseData>(`workspace/${data.workspace_id}/update`, {
        name: data.name
    }, { headers: { needLoading: true } })
}

export const createWorkspaceApi = (data: {
    name: string;
}) => {
    return SERVER_API.post<responseData>(`workspace/create`, {
        name: data.name
    }, { headers: { needLoading: true } })
}

export const getWorkspaceUserLinksApi = (data: {
    page: number;
    page_size: number;
    workspace_id: string;
    type?: string;
    active?: boolean;
    super_access?: boolean;
    pagination?: boolean;
}) => {
    const queryParams = new URLSearchParams();

    if (data.page) queryParams.append("page", data.page.toString());
    if (data.page_size) queryParams.append("page_size", data.page_size.toString());
    if (data.workspace_id) queryParams.append("workspace_id", data.workspace_id);
    if (data.type) queryParams.append("type", `${data.type}`);
    if (typeof data.active === "boolean") queryParams.append("active", `${data.active}`);
    if (typeof data.super_access === "boolean") queryParams.append("super_access", `${data.super_access}`);
    if (typeof data.pagination === "boolean") queryParams.append("pagination", `${data.pagination}`);

    return SERVER_API.get<responseData>(`workspace/${data.workspace_id}/user_links?${queryParams.toString()}`)
}

export const getWorkspaceAppLinksApi = (data: {
    page: number;
    page_size: number;
    workspace_id: string;
    pagination?: boolean;
}) => {
    const queryParams = new URLSearchParams();

    if (data.page) queryParams.append("page", data.page.toString());
    if (data.page_size) queryParams.append("page_size", data.page_size.toString());
    if (typeof data.pagination === "boolean") queryParams.append("pagination", `${data.pagination}`);

    return SERVER_API.get<responseData>(`workspace/${data.workspace_id}/all_apps?${queryParams.toString()}`)
}

export const createWorkspaceUserLinkApi = (data: {
    workspace_id: string;
    account: string;
    name: string;
    member_type?: string;
    locale?: string;
}) => {
    return SERVER_API.post<responseData>(`workspace/${data.workspace_id}/user_links/create`, {
        workspace_id: data.workspace_id,
        account: data.account,
        name: data.name,
        member_type: data.member_type,
        locale: data.locale,
    }, { headers: { needLoading: true } })
}

export const updateWorkspaceUserLinkApi = (data: {
    workspace_id: string;
    link_id: string;
    name?: string;
    account?: string;
    active?: boolean;
    member_type?: string;
}) => {
    return SERVER_API.put<responseData>(`workspace/${data.workspace_id}/user_links/${data.link_id}/update`, {
        ...( data.active !== null ? { active: data.active } : null ),
        ...( data.name ? { name: data.name } : null ),
        ...( data.account ? { account: data.account } : null ),
        ...( data.member_type ? { member_type: data.member_type } : null ),
    }, { headers: { needLoading: true } })
}

export const quitWorkspaceApi = (data: {
    workspace_id: string;
}) => {
    return SERVER_API.put<responseData>(`workspace/${data.workspace_id}/quit`, {
    }, { headers: { needLoading: true } })
}

export const refreshWorkspaceAuthTokenApi = (data: {
    workspace_id: string;
    link_id: string;
}) => {
    return SERVER_API.post<responseData>(
        `workspace/${data.workspace_id}/user_links/${data.link_id}/refresh_token`,
        {}, { headers: { needLoading: true } }
    )
}

export const getWorkspaceAppsApi = (data: {
    workspace_id: string;
}) => {
    return SERVER_API.get<responseData>(`workspace/${data.workspace_id}/apps`)
}

export const updateWorkspaceAppApi = (data: {
    workspace_id: string;
    app_id: string;
    active: boolean;
    config?: object;
    locale?: string;
}) => {
    return SERVER_API.put<responseData>(`workspace/${data.workspace_id}/apps/${data.app_id}/update`, {
        active: data.active,
        ...( data.config ? { config: data.config } : null ),
        ...( data.locale ? { locale: data.locale } : null ),
    }, { headers: { needLoading: true } })
}

export const getWorkspaceApiToken = (data: {
    workspace_id: string;
}) => {
    return SERVER_API.post<responseData>(`workspace/${data.workspace_id}/api_token`)
}

export const getAllWorkspace = () => {
    return SERVER_API.get<responseData>(`workspace/list`)
}

//chart view
export const getChartViewsApi = (data: {
    app_id: string;
    workspace_id: string;
    run_instance_id: string;
}) => {
    return SERVER_API.post<responseData>(`charts/list`, {
        app_id: data.app_id,
        workspace_id: data.workspace_id,
        ...( data.run_instance_id ? { run_instance_id: data.run_instance_id } : null ),
    }, {})
}

export const createChartViewApi = (data: {
    run_instance_id: string;
    name: string;
    filters: any;
    dataframe: string;
    template_config: any;
    chart_type: string;
    chart_config: any;
}) => {
    return SERVER_API.post<responseData>(`charts/create`, {
        run_instance_id: data.run_instance_id,
        name: data.name,
        filters: data.filters,
        dataframe: data.dataframe,
        template_config: data.template_config,
        chart_type: data.chart_type,
        chart_config: data.chart_config,
    }, { headers: { needLoading: true } })
}

export const updateChartViewApi = (data: {
    id: string;
    name?: string;
    filters?: any;
    dataframe?: string;
    template_config?: any;
    chart_type?: string;
    chart_config?: any;
    is_deleted?: boolean;
}) => {
    return SERVER_API.post<responseData>(`charts/update/${data.id}`, {
        ...( data.name ? { name: data.name } : null ),
        ...( data.filters ? { filters: data.filters } : null ),
        ...( data.dataframe ? { dataframe: data.dataframe } : null ),
        ...( data.template_config ? { template_config: data.template_config } : null ),
        ...( data.is_deleted !== null ? { is_deleted: data.is_deleted } : null ),
        ...( data.chart_type ? { chart_type: data.chart_type } : null ),
        ...( data.chart_config ? { chart_config: data.chart_config } : null ),
    }, { headers: { needLoading: true } })
}

export const saveLogApi = (data: {
    log: string;
    log_type: string;
    event_type: string;
}) => {
    return SERVER_API.post<responseData>(`system/save_log`, {
        ...( data.log ? { log: data.log } : null ),
        ...( data.log_type ? { log_type: data.log_type } : null ),
        ...( data.event_type ? { event_type: data.event_type } : null ),
    }, {})
}